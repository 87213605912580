/**
 * Plugin pro navázání událostí na jednotlivé komponenty na stránce
 * V kódu stačí volat $.eachComponent('.selector', function(context) {...}); kde selector určuje komponentu (komponenty)
 * a function je callback funkce volaná nad každou takovou komponentou při document.ready s parametrem context udávájící
 * jednu konkrétní komponentu
 *
 * @author Jan Preuss
 */

(function($) {

    $.extend({
        application: {

            ajaxSuccess: function(payload) {
                var app = $.application;

                // state
                if (payload.state) {
                    app.state = payload.state;
                }
                NProgress.inc();

                // title
                if (payload.title) {
                    document.title = payload.title.parts.join(payload.title.separator).split(payload.title.separator).reverse().join(payload.title.separator);
                    payload.title.parts.shift();
                    $('#content-title').html(payload.title.parts.join(payload.title.separator));
                }

                // redirect
                if (payload.redirect && payload.redirect.url) {
                    payload.redirect.url = payload.redirect.url.replace(/https?:/, location.protocol);
                    if(!payload.redirect.noajax) {
                        $.application.ajax(payload.redirect.url, {}, true);
                    }
                    else {
                        location.href = payload.redirect.url;
                    }
                }

                // dialog
                if(payload.dialog) {
                    app.handleDialogs(payload.dialog);
                }
                NProgress.inc();

                // snippets
                if (payload.snippets) {
                    for (var selector in payload.snippets) {
                        $("#" + selector).html(payload.snippets[selector]);
                    }
                }
                NProgress.set(0.8);

                // flashes
                if (payload.flashes) {
                    app.flashes.add(payload.flashes);
                }

                // help
                if (payload.help) {
                    app.help.setMessages(payload.help);
                }

                // debugBar (laděnka)
                if(payload.debugBar) {
                    var debug = $('#tracy-debug').size() > 0 ? $('#tracy-debug') : $('<div></div>').attr('id', 'tracy-debug').addClass('noajax').appendTo('body');
                    debug.html(unescape(payload.debugBar));
                    for (var i = 0, scripts = debug[0].getElementsByTagName('script'); i < scripts.length; i++) {
                        (window.execScript || function(data) {
                            window['eval'].call(window, data);
                        })(scripts[i].innerHTML);
                    }
                    Tracy.Dumper.init();
                    Tracy.Debug.init();
                    debug[0].style.display = 'block';
                }
                else {
                    $('#tracy-debug').remove();
                }

                if (payload.condition) {
                    app.condition = payload.condition;
                }
                else {
                    delete app.condition;
                }
                NProgress.inc();
            },

            // inicializace aplikace
            init: function() {
                var app = $.application;

                $('#tracy-debug').addClass('noajax');
                $('<div></div>').attr('id', 'arrowmess').appendTo('body');
                $('<div></div>').attr('id', 'variabless').appendTo('body');

                // načtení flashes přímo z html (při neajaxovém požadavku)
                app.flashes.init();

                // inicializace helpu (page guide)
                app.help.init();

                app.addEvents();

                app.addFocus();

                app.initFancybox();
            },

            ajax: function(url, data, toHistory) {
                // na pozadí běží požadavek na stejnou url
                if($.application.xhrs[url]) {
                    $.application.xhrs[url].abort(); // zabití předchozího požadavku
                }

                $.application.help.hide();

                $.application.xhrs[url] = $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    dataType: "json",
                    cache: false,
                    beforeSend: function() {
                        NProgress.start();
                        $('body').addClass('application-loading');
                    },
                    success: function(payload) {
                        NProgress.inc();
                        $.application.ajaxSuccess(payload);
                        $.application.addFocus();
                    },
                    complete: function() {
                        delete $.application.xhrs[url]; // odstranění url z fronty po dokončení
                        $.eachComponent('reload');
                        $('body').removeClass('application-loading');

                        NProgress.done();

                        // přidáváme do historie
                        if(toHistory === true && !/&do=/.test(url) && !/\/print\//.test(url) && history.pushState) { // jen pokud nejde o signál (...&do=...) nebo tisk (.../print/...)
                            history.pushState({
                                href: url
                            }, '', url);
                        }
                        $.application.variables.empty();
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        if(thrownError != "abort" && xhr.status !== 0){
                            $.application.flashes.add({
                                'message':'Error '+xhr.status+': '+thrownError,
                                'type': 'error'
                            });
                        }
                    }
                });

                return $.application.xhrs[url];
            },

            ajaxForm: function (url, data) {
                // na pozadí běží požadavek na stejnou url
                if ($.application.xhrs[url]) {
                    $.application.xhrs[url].abort(); // zabití předchozího požadavku
                }

                $.application.xhrs[url] = $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    beforeSend: function () {
                        NProgress.start();
                        $('body').addClass('application-loading');
                    },
                    success: function (payload) {
                        NProgress.inc();
                        $.application.ajaxSuccess(payload);
                        $.application.addFocus();
                    },
                    complete: function () {
                        delete $.application.xhrs[url]; // odstranění url z fronty po dokončení
                        $.eachComponent('reload');
                        $('body').removeClass('application-loading');

                        NProgress.done();
                        $.application.variables.empty();
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        if (thrownError != "abort" && xhr.status !== 0) {
                            $.application.flashes.add({
                                'message': 'Error ' + xhr.status + ': ' + thrownError,
                                'type': 'error'
                            });
                        }
                    }
                });

                return $.application.xhrs[url];
            },

            addEvents: function() {
                var app = $.application;

                if(!!(window.history && history.state)) {
                    window.onpopstate = function() {
                        app.ajax(document.location.href);
                    }
                }

                $('a:not(.noajax)').live('click', function (e) {
                    // žádný rodič není .noajax a prvek má atribut href
                    if($(this).parents('.noajax').size() <= 0 && $(this).attr('href') !== undefined && !/^javascript:/.test($(this).attr('href'))) {
                        e.preventDefault();
                        // Před přesměrováním se nejdřív zkusí odeslat form - jen pokud existuji tlacitka save/save_back
                        if($(this).is('.save-before-redirect') && !$(this).parent().hasClass('active') && $('#main form:first').size() == 1 && ($('#main form:first :submit.save').size() > 0 || $('#main form:first :submit.save_back').size() > 0)) {
                            var submit = "";
                            if($('#main form:first :submit.save').size() > 0) {
                                submit = "&save=" + $('#main form:first :submit.save').val();
                            }
                            else if($('#main form:first :submit.save_back').size() > 0) {
                                submit = "&save_back=" + $('#main form:first :submit.save_back').val();
                            }
                            /*else if($('#main form:first :submit.add').size() > 0) {
                                submit = "&add=" + $('#main form:first :submit.add').val();
                            }
                            else if($('#main form:first :submit.add_back').size() > 0) {
                                submit = "&add_back=" + $('#main form:first :submit.add_back').val();
                            }*/
                            $('#main form:first').trigger('submit', '&save-before-redirect=' + escape(this.href) + submit);
                        }
                        else {
                            app.ajax(this.href, {}, true);
                        }

                        //app.ajax(this.href, {}, true);
                    }


                });

                // odeslání eventem submit (nemělo by k němu dojít)
                $('form:not(.noajax)').live('submit', function (e, params) {
                    if($.type(params) == 'string') {
                        params = {
                            get: params,
                            noajax: false,
                            event: e
                        };
                    }

                    if(params.noajax != true) { // odeslani formu ajaxem
                        e.preventDefault();
                        var formData = new FormData();

                        if (params.get) {
                            $(params.get.replace('&', '').split('&')).each(function() {
                                var input = this.split('=');
                                formData.append(input[0], input[1]);
                            });
                        }

                        $('input[type="file"]', this).each(function(i, el) {
                            if (el.files.length > 0) {
                                formData.append(el.name, el.files[0]);
                            }
                        });

                        var other_data = $(this).serializeArray();
                        $.each(other_data,function(key, input){
                            formData.append(input.name, input.value);
                        });

                        $('#dialogExpand').dialog('close');
                        app.ajaxForm($(this).attr('action'), formData);
                    }
                    else { // klasicke odeslani (je treba pridat hidden misto stiskuteho tlacitka)
                        var hidden = $('<input type="hidden">')
                        .attr('name', $(params.event.target).attr('name'))
                        .attr('value', $(params.event.target).val());
                        $(this).append(hidden);
                    }
                });

                // odeslání kliknutím na tlačítko
                $('form:not(.noajax) :submit').live('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    if($(this).is('.dialog-close')) {
                        $(this).parents('.ui-dialog-content:first').dialog('close');
                    }
                    else {
                        var form = $(this).parents('form:first');
                        if($(this).is('.noajax')) { // noajax - vola klacisky submit nad formem
                            if($(this).is('.targetBlank')) {
                                form.attr('target', '_blank');
                            }
                            form.trigger('submit', {
                                get: '&' + escape($(this).attr('name')) + '=' + escape($(this).val()),
                                noajax: true,
                                event: e
                            });
                        }
                        else { // odesalni formu ajaxem
                            var formData = new FormData();
                            formData.append($(this).attr('name'), $(this).val());

                            $('input[type="file"]', form).each(function(i, el) {
                                if (el.files.length > 0) {
                                    formData.append(el.name, el.files[0]);
                                }
                            });

                            var other_data = form.serializeArray();
                            $.each(other_data,function(key, input){
                                formData.append(input.name, input.value);
                            });

                            $('#dialogExpand').dialog('close');
                            app.ajaxForm(form.attr("action"), formData);
                        }
                    }
                });

                // odeslání kliknutím na tlačítko
                $('form.noajax :submit').live('click', function (e) {
                    if($(this).is('.dialog-close')) {
                        e.preventDefault();
                        e.stopPropagation();
                        $(this).parents('.ui-dialog-content:first').dialog('close');
                    }
                });

                // odeslání formuláře enterem nad inputy
                $("form:not(.noajax) input").live('keypress', function (e) {
                    if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
                        e.preventDefault();
                        e.stopPropagation();
                        /*
                        var form = $(this).parents('form:first');
                        $('input[type=submit].default, input[type=submit]:last', form).first().click();
                        return false;
                        } else {
                        return true;
                        */
                    }
                });

            },

            handleDialogs: function(dialogsSettings) {
                var app = $.application;

                for (var id in dialogsSettings) {
                    var dialog;
                    if(app.dialogs[id]) {   // dialog už existuje
                        dialog = app.dialogs[id];
                    }
                    else {  // dialog neexistuje, je třeba ho vytvořit
                        dialog = $('<div></div>').attr('id', id);
                        app.dialogs[id] = dialog;
                        dialog.dialog({
                            width: 285,
                            minHeight: 90,
                            dialogClass: 'container_48',
                            autoOpen: false,
                            hide: {
                                effect: 'drop',
                                direction: "down"
                            },
                            modal: true,
                            resizable: false,
                            close: function(event, ui) {
                                $('#arrowmess').empty(); /* odstranění tooltipů při zavření dialogu */
                                app.variables.empty();
                            }
                        });

                    }

                    // nastavení všech vlastností dialogu
                    for(var option in dialogsSettings[id]) {
                        dialog.dialog('option', option, dialogsSettings[id][option]);
                    }

                    // akce s dialogem
                    switch(dialogsSettings[id].action) {
                        case 'open':
                            dialog.dialog('open');
                            break;
                        case 'close':
                            if(dialog.is(':visible')) {
                                dialog.dialog('close');
                            }
                            break;
                    }
                }
            },

            // Flash zprávy zobrazované nahoře na stránce
            flashes: {
                marginTop: 10, // odsazení zprávy od horní hrany okna
                delay: 4000,    // čas po který je jedna zpráva zobrazena
                enabled: true,  // zda je povoleno zobrazovat fashes
                messages: [],   // fronta zpráv
                timeout: null,  // objekt s timeoutem při volání funkce hide
                disable: function() {
                    $.application.flashes.enabled = false;
                }, // zakázání zobrazování fashes
                enable: function() {
                    $.application.flashes.enabled = true;
                }, // povolení zobrazování fashes
                init:function () {
                    if($('.message-box').size() != 1){
                        return;
                    }
                    $('.message-box').show();
                    var top = $('.message-box').offset().top - parseFloat($('.message-box').css('marginTop').replace(/auto/, 0)) - $.application.flashes.marginTop;
                    var orig_width = $('.message-box').width();
                    var orig_left = ($('.message-box').parent().outerWidth()-orig_width)/2;
                    var orig_top = parseFloat($('.message-box').css('top'));
                    $('.message-box').hide();

                    $(window).scroll(function (event) {
                        // what the y position of the scroll is
                        var y = $(this).scrollTop();
                        // whether that's below the form
                        if (y >= top) {
                            // if so, ad the fixed class
                            $('.message-box').css({
                                'position': 'fixed',
                                'left': ((document.body.offsetWidth-orig_width)/2),
                                'top' : $.application.flashes.marginTop
                            });
                        } else {
                            // otherwise remove it
                            $('.message-box').css({
                                'position': 'absolute',
                                'left': orig_left,
                                'top' : orig_top
                            });
                        }
                    });

                    // Přidání zpráv předaných atributem
                    $.application.flashes.add($.parseJSON($('.message-box').attr('data-flashes')));
                },
                add: function(msg) { // přidání nové zprávy
                    var msgs = $.application.flashes.messages;
                    if(isArray(msg)) {
                        //for(var i in msg) {
                        msg.each(function(i) {
                            msgs.push(msg[i]);
                        });
                    }
                    else {
                        msgs.push(msg);
                    }

                    $.application.flashes.show();
                },
                get: function() {
                    return $.application.flashes.messages.shift();
                }, // vrácení první následující zprávy v pořadí
                show: function() { // zobrazení nové zprávy
                    var flashes = $.application.flashes;
                    if(flashes.enabled) {
                        var messageBox = $('.message-box');
                        var messageBoxContent = $('.message-box-content', messageBox);

                        var msg = flashes.get();
                        if(msg) {
                            messageBox.removeClass('error info success warning');

                            messageBox.addClass(msg.type);
                            messageBoxContent.html(msg.message);

                            messageBox.fadeIn();
                            clearTimeout(flashes.timeout);
                            flashes.timeout = setTimeout(flashes.hide, flashes.delay);
                        }
                    }
                },
                hide: function() { // skrytí zprávy po určitém timeoutu
                    var messageBox = $('.message-box');
                    messageBox.fadeOut();
                    $.application.flashes.show();
                }
            },

            help: {
                pulse: true,
                shadowPadding: 3,
                direction: 'right',
                messages: [],
                init: function () {
                    $.pageguide('options', {
                        pulse: $.application.help.pulse,              // Turn the pulse effect back on, it's rad
                        step: { shadowPadding: $.application.help.shadowPadding },
                        events: {
                            load: function(e){
                                if($.pageguide('guide').steps.length > 0) {
                                    $('#pageGuideToggle', this).addClass('active');
                                }
                                else {
                                    $('#pageGuideToggle', this).removeClass('active');
                                }
                            }
                        }
                    });
                },
                setMessages: function(msgs) {
                    var steps = [];
                    $.application.help.messages = msgs;
                    $.application.help.messages.each(function(i, msg){
                        var step = clone(msg);
                        step.content = step.content.replace(/\n/gi, "<br>");
                        steps.push(step);
                    });
                    $.pageguide({ steps: steps });
                },
                getMessages: function() {
                    return $.application.help.messages;
                },
                hide: function() {
                    $.pageguide('close');
                }
            },

            addFocus: function() { // Nastaví focus prvnímu prvku s třídou .focus
                $('input.focus:first').focus();
            },

            initFancybox: function() { // inicializuje FancyBox
                $("a.fancybox").fancybox({
                    type: 'image'
                });
            },

            progress: {
                show: function(message, title) {
                    $.application.handleDialogs({'root-progress': {action: 'open', title: title, width: 400, 'min-height': 90} });
                    var div = $('<div class="progress-bar"><div class="progress-label"></div></div>').progressbar({ value: false });
                    $('#root-progress').html(div);
                    $('#root-progress .progress-label').text(message);
                },
                hide: function() { $.application.handleDialogs({'root-progress': {action: 'close'} }); },
                setValue: function(value) {
                    $('#root-progress .progress-bar').progressbar( "value", value );
                }
            },

            arrowMessage: {
                show: function(element, type, message){
                    $('#arrowmess').empty();
                    type = type || 'error';
                    message = message || '';
                    var pos = $(element).offset();
                    var width = $(element).outerWidth();
                    var finetooltip = $('<div class="arrowmess '+type+'"><div class="arrowmess-arrow"></div><div class="arrowmess-body"><div>'+message+'</div></div></div>').appendTo('#arrowmess');
                    finetooltip.css({
                        top: (pos.top - 6),
                        left: (pos.left + width + 10),
                        whiteSpace: 'nowrap'
                    });
                    finetooltip.show();
                    if($('.arrowmess-body', finetooltip).width() > 250) {
                        finetooltip.css({
                            width: 250,
                            whiteSpace: 'normal'
                        });
                    }
                },
                hide: function(){
                    $('#arrowmess').empty();
                }
            },

            state: {},

            dialogs: [],

            xhrs: {},

            variables: {
                empty: function(){
                    $('#variabless').empty();
                }
            }
        }
    });

})(jQuery);
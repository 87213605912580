$.eachComponent(".products_discounts", function(table) {
    $(".discounts_checkbox", table).each(function() {
        if (!$(this).is(":checked")) {
            $(this).closest("td").nextAll("td").find("input").each(function() {
                $(this).attr("disabled", "disabled");
                $(this).hide();
            });
        }
        else {
            $(this).closest("td").nextAll("td").find("input").each(function() {
                if (!$(this).hasClass("cannot_enable")) {
                    $(this).removeAttr("disabled");
                }
                else {
                    $(this).attr("disabled", "disabled");
                }
            });
        }
        
        $(this).change(function() {
           if (!$(this).is(":checked")) {
               $(this).closest("td").nextAll("td").find("input").each(function() {
                   $(this).hide();
                   $(this).attr("disabled", "disabled");
               });    
           }
           else {
               $(this).closest("td").nextAll("td").find("input").each(function() {
                    $(this).show();
                    $(this).removeAttr("disabled");
                    if ($(this).hasClass("cannot_enable")) {
                        $(this).attr("disabled", "disabled");
                    }
               })
           }
        });
        
    }); 
    
    
    
    
});
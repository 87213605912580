$.eachComponent('.contracts_series', function(context) {
    if($('select[name="date_type"]', context).val() != 0) {
        $('input[name="number"]', context).addClass("readonly");
    }
    
    $('select[name="date_type"]', context).change(function() {
        if($(this).val() != 0) {
            $('input[name="number"]', context).addClass("readonly");
        }
        else {
            $('input[name="number"]', context).removeClass("readonly");
        }
    });
    
    $('.all input:checkbox', context).change(function() {
        if($(this).attr('checked')) {
            $('.firms', context).find('.firm input:checkbox').attr('checked', 'checked');
        }
        else {
            $('.firms', context).find('.firm input:checkbox').removeAttr('checked');
        }
    });
    
    $('.firm input:checkbox', context).change(function() {
        if($(this).attr('checked')) {
            if($('.firms', context).find('.firm input:checkbox').length == $('.firms', context).find('.firm input:checkbox:checked').length) {
                $('.firms', context).find('.all input:checkbox').attr('checked', 'checked');
            }
        }
        else {
            $('.firms', context).find('.all input:checkbox').removeAttr('checked');
        }
    });
    
    $('.firm input:checkbox', context).change();
});



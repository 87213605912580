
// Schování menu při kliknutí kamkoliv do stránky
$().ready(function() {
    $('html').bind('click dblclick contextmenu', function(){
        $('#infopanel').hide();
    });
});

$.eachComponent('header', function(context) {

    $(context).on('click','#nav-marker-view-menu',function(event){
        event.stopPropagation();
        $('#infopanel').toggle();
    });

    $(context).on('click','#general_helper_menu',function(event){
        event.stopPropagation();
        $('#infopanel_help').toggle();
    });


});
/*
 * JS pro datastorage grid
 */

$.eachComponent('.datastorage-explorer .grid .dataGrid-body-row', function(row) {

    var grid = $(row).parents('.grid:first');
    var explorer = $(grid).parents('.datastorage-explorer:first');
    var explorerId = $(explorer).parent().attr('id');

    $.datastorage.fileserverReady(function() {
        var url = $.datastorage.getUrl();
        // po připojení k fileserveru zaktivníme odkazy na soubory
        $("a[data-file]", row).each(function(i, elm) {
            if(!$(elm).data('disabled')) {
                if ($(elm).data('file').locked_by === null)
                {   
                    $('div', elm).removeClass('disabled'); 
                    $(elm).attr('href', url + $(elm).data('file').hash);
                }
                else
                {   $('div', elm).addClass('disabled');  }
            }
            
        });
        console.datastorage('activate links to files in datastorage explorer');
    });

    $.datastorage.appletReady(function() {
        // po načtení appletu necháme subory otevírat applet
        $("a[data-file]", row).each(function(i, file) {
            
            if ($(file).data('file').locked_by === null) 
            {
                $(file).click(function(e) {
                    e.preventDefault();
                    var data = $(file).data('file');
                    console.datastorage('opening file using applet:', data);
                    var hash = data.hash.split('-');
                    var id = hash.shift();
                    hash.shift();
                    var parent = $(explorer).data('datastorage-explorer-options').parent;
                    $.datastorage.applet.openFile(explorerId, $(file).attr('href'), data.name, id, hash.join('-'), parent);
                });
            }
            
        });
        console.datastorage('activate opening file using applet');
    });

});

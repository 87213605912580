$.eachComponent('.configurator_wizards_cards', function(context) {
    function showHideThings(type) {
        switch(type) {
            case "5":
                $('.parameters', context).hide();
                $('.options', context).hide();
                $('.groups', context).hide();
                $('.targets', context).hide();
                $('.wizards', context).show();
                $('.grid', context).hide();
                $('.layout', context).hide();
                $('.wizard', context).show();
                $('.conditions_views', context).show();
				$('.count_at_row', context).hide();
                break;
            case "4":
                $('.parameters', context).hide();
                $('.options', context).hide();
                $('.groups', context).hide();
                $('.wizards', context).hide();
                $('.targets', context).show();
                $('.grid', context).show();
                $('.layout', context).hide();
                $('.wizard', context).hide();
                $('.conditions_views', context).hide();
				$('.count_at_row', context).hide();
                break;
            case "3":
                $('.targets', context).hide();
                $('.parameters', context).hide();
                $('.options', context).hide();
                $('.wizards', context).hide();
                $('.groups', context).show();
                $('.grid', context).show();
                $('.layout', context).hide();
                $('.wizard', context).hide();
                $('.conditions_views', context).show();
				$('.count_at_row', context).hide();
                break;
            case "2":
                $('.targets', context).hide();
                $('.parameters', context).hide();
                $('.groups', context).hide();
                $('.wizards', context).hide();
                $('.options', context).show();
                $('.grid', context).show();
                $('.layout', context).show();
                $('.wizard', context).hide();
                $('.conditions_views', context).show();
				$('.count_at_row', context).show();
                break;
            default:
                $('.targets', context).hide();
                $('.options', context).hide();
                $('.groups', context).hide();
                $('.wizards', context).hide();
                $('.parameters', context).show();
                $('.grid', context).show();
                $('.layout', context).show();
                $('.wizard', context).hide();
                $('.conditions_views', context).show();
				$('.count_at_row', context).show();
                break;
        }
    }

    $(context).on('change', 'select[name="type"]', function(e){
        var type = ($(e.target).val());
        showHideThings(type);
        $('select[name="layout"]', context).change();
    });

    $(context).on('change', 'select[name="fk_configurator_wizards_next"]', function(e) {
        $('input[name=reinit]', $(context).parents('form')).click();
    });

    $(context).on('change', 'select[name="layout"]', function(e) {
        if($(this).val() == 1) {
            $('.count_at_row', context).hide();
        }
        else {
            $('.count_at_row', context).show();
        }
    });

    $('select[name="type"]', context).change();

    $('select[name="layout"]', context).change();

    if($('input[name="type"]', context).attr('type') == "hidden"){
        var type = $('input[name="type"]', context).val();
        showHideThings(type);
    }
});


$.eachComponent('.configurator_wizards_cards_dialog', function(context) {
    $(context).on('change', 'select[name="fk_configurator_parameters"]', function(e) {
        $('input[name=reinit]', $(context).parents('form')).click();
    });

    $(context).on('change', 'select[name="type"]', function(e) {
        var type = ($(e.target).val());
        switch(type) {
            case "2":
                $('.group_subgroup', context).hide();
                $('.product', context).show();
                $('.method_of_selection', context).hide();
                $('.sel', context).hide();
                break;
            default:
                $('.product', context).hide();
                $('.group_subgroup', context).show();
                $('.method_of_selection', context).show();
                $('.sel', context).show();
                break;
        }
    });

    $('select[name="type"]', context).change();
});

// Výběr komponent - skrývání min a max
$.eachComponent('#dialog_edit_group', function(context){
   if($('input[name="quantity_option"]', context).is(':checked')){
       $('.minmax', context).show();
   }else{
       $('.minmax', context).hide();
       $('input[name="min"]', context).val("");
       $('input[name="max"]', context).val("");
   }
   $(context).on('change', 'input[name="quantity_option"]', function(e) {
       if($(this).is(':checked')){
           $('.minmax', context).show();
       }else{
           $('.minmax', context).hide();
           $('input[name="min"]', context).val("");
           $('input[name="max"]', context).val("");
       }
   });
});
$.eachComponent('.firms_general', function(context) {
    $(context).on('change', 'select[name="fk_countries"]', function(e) {
        $('input[name=reinit]', $(context).parents('form')).click();
    });
});

$.eachComponent('input[name="user[changePassword]"]', function(context) {
   $(context).click(function () {
       $('.password-change').toggle();
   })
});


/*
 * Slouží k odstínění console.log v prohlížečích, které je nepodporují.
 * Zároveň přidává novou funkcionalitu.
 */


if(!window.console) {
    window.console = {}
}

if(!window.console.log) {
	window.console.log = function(){};
}

if(!window.console.debug) {
	window.console.debug = window.console.log;
    if(!window.console.debug.apply) {
        window.console.debug = function(){};
    }
}

if(!window.console.info) {
	window.console.info = window.console.log;
    if(!window.console.info.apply) {
        window.console.info = function(){};
    }
}

if(!window.console.warn) {
	window.console.warn = window.console.log;
    if(!window.console.warn.apply) {
        window.console.warn = function(){};
    }
}

if(!window.console.error) {
	window.console.error = window.console.log;
    if(!window.console.error.apply) {
        window.console.error = function(){};
    }
}

if(!window.console.assert) {
	window.console.assert = function(){};
}

if(!window.console.trace) {
	window.console.trace = function(){};
}
$.eachComponent(".group-checkbox", function (checkbox) {

    $(checkbox).on('change', function () {
        var id = $(this).data('id');
        if ($(this).is(':checked')) {
            $('input.group-checkbox[data-parent="' + id + '"]').attr('checked', 'checked');
        } else {
            $('input.group-checkbox[data-parent="' + id + '"]').removeAttr('checked');
        }
    });

    var parentDiv = $(checkbox).parent();
    var label = $("label", parentDiv);
    if ($(checkbox).hasClass("offset")) {
        $(label).width($(label).width() - 20);
        $(label).css({"padding-left": "20px", "line-height": "15px"});
    }
    if ($(".no-offset", parentDiv).length > 0) {
        $(parentDiv).css({'border-bottom': "1px solid #82CFFD"});
    }
});

/*
 * To change this template, choose Tools | Templates
 * and open the template in the editor.
 */

$.eachComponent(".datastorage-explorer .contextmenu", function(contextmenu){

    var explorer = $(contextmenu).parents('.datastorage-explorer:first');
    var grid = $(".grid", explorer);
    var row;

    // skryti a disablovani polozek zavyslich na appletu
    $('.item.copy, .item.paste', contextmenu).hide();
    $('.item.paste', contextmenu).addClass('disabled');
    $('.item.paste .icon-16', contextmenu).addClass('disabled');

    // opetovne povoleni polozek po nacteni appletu
    $.datastorage.appletReady(function() {
        $('.item.copy, .item.paste', contextmenu).show();
    });

    // nabindovani kontextové menu
    $(grid).bind('contextmenu', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var parentOffset = $(grid).offsetParent().offset();

        // ulozeni radku, nad ktery bylo kliknuto
        row = $(e.target).parents('.dataGrid-body-row:first');

        $(contextmenu).css({
            top: e.pageY - parentOffset.top,
            left: e.pageX - parentOffset.left
        });

        // skrytí všech ostatních menu
        $('.datastorage-explorer .grid .contextmenu').hide();
        // zobrazeni menu
        $(contextmenu).show();

        if(row.size() == 1) { $('.item.open, .item.copy, .item.open, .item.delete, .item.rename, .item.history', contextmenu).show(); }
        else { $('.item.open, .item.copy, .item.open, .item.delete, .item.rename, .item.history', contextmenu).hide(); }

        // skryvani polozky "vlozit" podle aktualniho stavu schranky
        if($('.item.paste', contextmenu).is(':visible')) {
            if(!$.datastorage.applet.emptyClipboard()) {
                $('.item.paste', contextmenu).addClass('disabled');
                $('.item.paste .icon-16', contextmenu).addClass('disabled');
            }
            else {
                $('.item.paste', contextmenu).removeClass('disabled');
                $('.item.paste .icon-16', contextmenu).removeClass('disabled');
            }
        }
    });

    // bindovani jednotlivych polozek
    // otevreni
    $('.item.open', contextmenu).click(function(e) {
        if(row) { $('.dataGrid-body-button.edit', row).click(); }
    });

    // smazani
    $('.item.delete', contextmenu).click(function(e) {
        if(row) { $('.dataGrid-body-button.delete', row).click(); }
    });

    // historie
    $('.item.history', contextmenu).click(function(e) {
        if(row) { $('.dataGrid-body-button.history', row).click(); }
    });

    // nova slozka
    $('.item.new_folder', contextmenu).click(function(e) {
        $('.head .head-button.new', explorer).click();
    });

    // vlozit
    $('.item.paste', contextmenu).click(function(e) {
        if($.datastorage.applet.emptyClipboard()) {
            console.datastorage('start paste files');
            $.datastorage.applet.prePasteFile(explorer.parent().attr('id'));
        }
    });

    // kopirovat
    $('.item.copy', contextmenu).click(function(e) {
        if(row.size() > 0) {
            var files = [];
            files.push([$('.datagrid-column-href a', row).attr('href'), $('.datagrid-column-href a', row).text(), '']);
            console.datastorage('start copy to clipboard:', files);
            $.datastorage.applet.copyFile(explorer.parent('id'), files);
        }
    });

    // skrývání kontextového menu
    $("body").bind('click dblclick contextmenu contextsubmenu', function(e) {
        row = $('');
        $(contextmenu).hide();
    });


    // jen pokud je applet
    $.datastorage.appletReady(function() {
        console.datastorage('bind copy & paste keys');
        var ctrlKey = false;
        // Nabindování stisku kláves
        $("body").bind('keydown', function(e) {
            if(e.which == 17) { // ctrl
                ctrlKey = true;
            }
        }).bind('keyup', function(e) {
            if(e.which == 17) {	// ctrl
                ctrlKey = false;
            }
            else if(e.which == 67 && ctrlKey) { // ctrl-c
                $('.item.copy', contextmenu).click();
            }
            else if(e.which == 86 && ctrlKey) { // ctrl-v
                $('.item.paste', contextmenu).click();
            }
        });
    });

});

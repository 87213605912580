$.eachComponent('.configurator-products-prices', function(context) {
    $('.select_price select', context).each(function(i, element) {
        if($(element).attr('data-main')){
            $(element).hide();
        }
    });
    $('.reloadeable', context).change(function(){
       $('input[name="reload"]', context).click();
    });

    $('.currency_change', context).change(function(){
       $('input[name="currency_change"]', context).click();
    });

    if($(".prices-matrix-container", context).width() >= 920){
        if(!$('#prices-matrix', context).hasClass("scrolling-x")){
            $('#prices-matrix', context).addClass("scrolling-x");
        }
    }else{
        if(!$('#prices-matrix', context).hasClass("scrolling-x")){
            $('#prices-matrix', context).removeClass("scrolling-x");
        }
    }

    // nagenerovani kodu jako v Excelu (A1, B1, C1...)
    $('.matrix_generate_codes .refresh', context).click(function(){
        var prefix = $('.matrix_generate_codes input[type=text]').val();

        $('.prices-matrix-container tr', context).each(function(y, row) {
            $('td', row).each(function(x, col) {
                $("input[name^='pricesMatrix[matrix]']", col).each(function(i, input){
                    var name = $(input).attr('name');
                    if(/\[code\]/.test(name)) {
                        var suffix = "." + x.toLetters() + y;
                        $(input).val(prefix + suffix);
                    }
                });
            });
        });
    });

    $('.import_prices input:file', context).change(function() {
        $('input[name="import_prices"]', context).click();
    });

});
/*
 * To change this template, choose Tools | Templates
 * and open the template in the editor.
 */

$.eachComponent(".datastorage-explorer .head .head-uploadButton", function(button) {

    var datastorageApplet = $('.datastorage-applet:first').data('options');
    var datastorageHead = $(button).parents('.head:first');
    var datastorageExplorer = $(button).parents('.datastorage-explorer:first');
    var currentFolder = $('.breadcrumb a:last', datastorageHead); // posledni odkaz v breadcrumb (chtelo by to upravit lepe)

    var options = datastorageExplorer.data('datastorage-explorer-options');

    // Inicializace třídy pro nahrávání souboru drag&dropem a přes input
    $.datastorage.fileserverReady(function() {
        console.datastorage('initializing fileupload js');
        $('input', button).fileupload({
            url: $.datastorage.getUrl(),
            dropZone: datastorageExplorer,
            dataType: 'json',
            singleFileUploads: false,
            sequentialUploads: true,
            maxChunkSize: 0,
            always: function(e, data) {
                if(data && data.result && !data.result.ok) {
                    console.datastorage('fileupload ERROR', data.result);
                }
            },
            // upload na server
            submit: function(e, data) {},

            // začátek nahrávání
            start: function(e) {
                console.datastorage('fileupload started', e);
                //datastorage.trigger('uploadStart');
            },

            // konec nahrávání
            stop: function() {
                console.datastorage('fileupload finished');
                //datastorage.trigger('uploadStop');
                currentFolder.click();
            },

            // přidání souborů (hned se pokouší odeslat na server)
            add: function(e, data) {
                if(data.files.length < 1) {
                    return;
                }

                e.preventDefault();

                var formData = [
                    { name: "datastorage[action]", value: "save" },
                    { name: "datastorage[data][parent]", value: options.parent },
                    { name: "datastorage[data][user]", value: options.user },
                    { name: "datastorage[data][hash]", value: options.hash },
                    { name: "APC_UPLOAD_PROGRESS", value: options.APC_UPLOAD_PROGRESS }
                ];

                data.files.each(function(key, value) {
                    formData.push({
                        name: "datastorage[filesattr]["+key+"][overwrite]",
                        value: true
                    });
                });

                // nastavení odesílaných dat
                $('input', button).fileupload('option', {
                    formData: formData
                });

                console.datastorage('fileupload files added');

                // odeslani souboru na server
                data.submit();

            },

            paste: function() {
                if($('input:focus').length > 0 || $('textarea:focus').length > 0) {

                } else {
                    return false; // ošetření defaultní akce při paste, aby neházela chybu
                }
            }
        });
    });

});

$.eachComponent('.tasks-form-content', function(context) {
    
    $('select[name="fk_tasks_types"]', context).change(function(){
        $('input[name="reload"]').click();
    });
   
    // ------------------------------------------------------
    
    if($('input[name="automatic"]', context).is(':checked')){
        $('.automatic-task', context).show();
    }else{
        $('.automatic-task', context).hide();
    }
    
    $('input[name="automatic"]', context).change(function(){
        if($(this).is(':checked')){
           $('.automatic-task', context).show();
        }else{
            $('.automatic-task', context).hide();
        }
    });
    
    // ------------------------------------------------------
    
    if($('select[name="fk_tasks_periods"]', context).val() == 7){
        $('.day_of_week', context).show();
    }else{
        $('.day_of_week', context).hide(); 
    }
    
    $('select[name="fk_tasks_periods"]', context).change(function(){
        if($('select[name="fk_tasks_periods"]', context).val() == 7){
            $('.day_of_week', context).show();
        }else{
            $('.day_of_week', context).hide(); 
        }
    });
    
});
$.eachComponent('.configurator-parameters', function(context) {
    $().ready(function () {
        $('html').bind('click dblclick contextmenu', function (e) {
            if ($(e.target).parents('.dataGrid-body-row').length == 0) {
                $('.dataGrid-body-row').removeClass('selected');
            }
        });
    });

    $('select[name="select_types"]', context).on('change', function() {
        $('#type_selected_submit').click();
    });

    $('.reloadeable input[type="checkbox"]', context).on('change', function() {
        if ($('select[name="select_types"]').val() == "8") {
            $('input[name="reload"]').click();
        }
    });

    $('.reloadeable select', context).on('change', function(){
        $('input[name="reload"]').click();
    });

    if ($(".matrix-container", context).width() >= 920) {
        if (!$('#matrix', context).hasClass("scrolling-x")) {
            $('#matrix', context).addClass("scrolling-x");
        }
    } else {
        if (!$('#matrix', context).hasClass("scrolling-x")) {
            $('#matrix', context).removeClass("scrolling-x");
        }
    }

    $('.import_parameter input:file', context).change(function() {
        $('input[name="import_parameter"]').click();
    });
});
/* 
 * Třída pro práci s grafem pomocí knihovny SigmaJS
 * Author: Michal
 * canvasContainer = prázdný element DIV, který bude obalovat canvasy 
 * graphJSON = JSON s detaily grafu
 */
function SynopsisGraph(canvasContainer, graphJSON) {
    var self = this;
    //div container
    var canvasContainer = canvasContainer;
    //json s detailem grafu (uzny, hrany, souřadnice)
    var graphJSON = graphJSON;
    //objekt reprezentující graf z knihovny sigmaJS
    var sigInst;
    //velikost uzlu, vzdálenost mezi uzly v ose X, vzdálenost v ose Y
    var nodeSize, nodeSizeCoef = 10, nodeRangeX, nodeRangeY;
    //startovací souřadnice
    var startX, startY;
    //hint popup
    var popUp;
    //počáteční šířka, výška
    var startWidth = $(canvasContainer).width();
    var startHeight = $(canvasContainer).height();
    //dynamická velikost?
    var graphResizable = true;
    //při změně o kolik pixelů se má překreslit graf
    var resizeStep = 30;
    //poslední šířka, výška (kvůli měřěení, jestli se už má překreslit)
    var lastWidth, lastHeight;
    //canvas se roztahuje libovolně až do fullscreenu
    var fullscreenMode = true;
    //barvy uzlů
    var nodeColor = "#000000";
    var notExistingNodeColor = "grey";
    var selectedNodeColor = "red";
    //vlastnosti kreslení a grafu defaultní (dá se měnit v průběhu)
    var drawingProperties = {
            defaultLabelColor: '#000000',
            defaultLabelSize: 12,
            defaultLabelBGColor: '#fff',
            defaultLabelHoverColor: '#000',
            labelThreshold: 2,
            defaultEdgeType: 'curve',
    };
    var graphProperties = {
            minNodeSize: 1,
            maxNodeSize: 5,
            minEdgeSize: 1,
            maxEdgeSize: 2
     };
     
     
     //výška a šířka canvasu
     var canvasWidth = function(width) {
         if (width != null) {
             $(canvasContainer).width(width);
             $(canvasContainer).children().each(function() {
                 $(this).width(width);
             });
             lastWidth = width;
         }
         else {
             return $(canvasContainer).width();
         } 
         
     };
     var canvasHeight = function(height) {
         if (height != null) {
             $(canvasContainer).height(height);
             $(canvasContainer).children().each(function() {
                 $(this).height(height);
             });
             lastHeight = height;
         }
         else {
             return $(canvasContainer).height();
         }
     };
     
     
     //inicializace
    __construct = function(that) {
        
        initGraph();
        
        sigInst.bind('overnodes',showNodeInfo).bind('outnodes',hideNodeInfo);
       // sigInst.bind('downnodes',onClick);
        
        $(window).resize(function() {
            if (graphResizable) {
                //pokud je něco menší jak default, tak zmenšuj
                if ((window.innerWidth < canvasWidth() - resizeStep) || (window.innerHeight < canvasHeight() - resizeStep)) {
                    canvasWidth(window.innerWidth);
                    canvasHeight(window.innerHeight);
                    graphProperties.maxNodeSize = calcNodeSize();
                    sigInst.graphProperties(graphProperties);
                    that.redraw();
                }
                //pokud je něco větší jak container a menší jak default, tak zvětšuj 
               // if (($(window).width() > canvasWidth() + resizeStep) || ($(window).height() > canvasHeight() + resizeStep)) {
                if ((window.innerWidth > canvasWidth() + resizeStep) || (window.innerHeight > canvasHeight() + resizeStep)) {
                    if ((window.innerWidth < startWidth || (window.innerWidth > startWidth && fullscreenMode)) && 
                        (window.innerHeight < startHeight || (window.innerHeight > startHeight && fullscreenMode))) {
                        canvasWidth(window.innerWidth);
                        canvasHeight(window.innerHeight);
                        graphProperties.maxNodeSize = calcNodeSize();
                        sigInst.graphProperties(graphProperties);
                        that.redraw();
                    }
                }  
            }
        });
   }(this);
    
   
    //////////////////////////PRIVATE FUNKCE///////////////////////////////////
    //vytvoření grafu 
    function initGraph() {
        //init
        nodeRangeX = calcNodeRangeX();
        nodeRangeY = calcNodeRangeY();
        graphProperties.maxNodeSize = calcNodeSize();
        calcStartCoords();
        sigInst = sigma.init(canvasContainer);
        sigInst.drawingProperties(drawingProperties);
        sigInst.graphProperties(graphProperties);
        createNodes();
        createEdges();
    };
    
    //výpočet velikosti uzlu
    function calcNodeSize() {
        var size = canvasHeight()/getGraphHeight()/nodeSizeCoef;
        return size < graphProperties.minNodeSize ? graphProperties.minNodeSize : size;
                                            //      : size > graphProperties.maxNodeSize ? graphProperties.maxNodeSize 
                                            //                                           : size;
    };
    
    //výpočet offsetu v X
    function calcNodeRangeX() {
        //return Math.sqrt(calcNodeSize()/$(canvasContainer).height());
        return 1/((getGraphWidth()/canvasWidth()) * canvasWidth());
    };
    
    //výpočet offsetu v Y
    function calcNodeRangeY() {
        //return Math.sqrt(calcNodeSize()/$(canvasContainer).width());
        return 1/((getGraphHeight()/canvasHeight()) * canvasHeight());
    };
    
    //startovací souřadnice
    function calcStartCoords() {
        startX = - 1;
        startY = - 1;
    }
    
    //vytvoří uzlya přidá je do grafu
    function createNodes() {
        console.log(graphJSON);
        for (var i = 0; i<graphJSON.length;i++) {
            var nodeDetail = graphJSON[i];
            canvasCoords = calcCanvasCoords(nodeDetail.coords);
            sigInst.addNode(nodeDetail.number, {
                                x: canvasCoords.x,
                                y: canvasCoords.y,
                                label: nodeDetail.number + "-" + nodeDetail.description,
                                size: calcNodeSize(),
                                color: nodeDetail.exists === true ? nodeColor : notExistingNodeColor,
                                attributes: {
                                    //bude zobrazený v hintu
                                    visible: {
                                        description : nodeDetail.description
                                    },
                                    //cokoliv, co chceme u uzlu znát
                                    hidden: {
                                        number: nodeDetail.number,
                                        exists: nodeDetail.exists,
                                        id: nodeDetail.id
                                    }
                                }
                            }
            );
        }
    }; 
    
    //vytvoří hrany
    function createEdges() {
        for (var i = 0; i<graphJSON.length;i++) {
            var nodeDetail = graphJSON[i];
            for(var j = 0;j<nodeDetail.descendants.length;j++) {
                sigInst.addEdge(nodeDetail.number + "-" + j, nodeDetail.number, nodeDetail.descendants_numbers[j]);
            }    
        }
    };
    
    //vrátí real souřadnice na canvasu
    function calcCanvasCoords(matrixCoords) {
        return  {
          x: [startX + (matrixCoords.x * nodeRangeX)],
          y: [startY + (matrixCoords.y * nodeRangeY)]
        };
    };
    
    //vrací výšku grafu v jednotkách
    function getGraphHeight() {
        var max = 0;
        for (var i = 0; i<graphJSON.length;i++) {
            if (graphJSON[i].coords.y > max) {
                max = graphJSON[i].coords.y;
            }
        }
        return max + 1;
    };
    
    //vrací šířku v jednotkách
    function getGraphWidth() {
        var max = 0;
        for (var i = 0; i<graphJSON.length;i++) {
            if (graphJSON[i].coords.x > max) {
                max = graphJSON[i].coords.x;
            }
        }
        return max + 1;
    };
    
    
    /////////////////////////public functions//////////////////////
    //vykresli
    this.draw = function() {
        sigInst.draw();
    };
    
    this.redraw = function() {
        this.clear();
        console.log("redrawing...");
        this.draw();
    };
    
    this.clear = function() {
        $(canvasContainer).children("canvas").each(function() {
            var context = this.getContext("2d");
            context.clearRect(0, 0, this.width, this.height);
        });
    };
    
    //vypnutí/zapnutí defaultního hintu
    this.useDefaultMouseOverNode = function(enabled) {
        if (enabled) {
            sigInst.bind('overnodes',showNodeInfo).bind('outnodes',hideNodeInfo);
        }
        else {
            sigInst.unbind('overnodes').unbind('outnodes');
        }
    };
    
    //vypnutí/zapnutí defaultního clicku
    this.useDefaultOnClick = function(enabled) {
        if (enabled) {
            sigInst.bind('downnodes',onClick);
        }
        else {
            sigInst.unbind('downnodes');
        }
    };
    
    this.bind = function(event, func) {
        sigInst.bind(event, func);
    };
    
    this.unbind = function(event) {
        sigInst.unbind(event);
    };
    
    this.graphResizable = function(resizable) {
        graphResizable = resizable;
    };
    
    //nastavení vykreslování
    this.setDrawingProperties = function(drawingProps) {
        drawingProperties = drawingProps;
        sigInst.drawingProperties(drawingProperties);
    };
    this.setDrawingProperty = function(drawingPropertyName, propertyValue) {
        drawingProperties[drawingPropertyName] = propertyValue;
        sigInst.drawingProperties(drawingProperties);
    };
    //nastavení vlastností grafu
    this.setGraphProperties = function(graphProps) {
        graphProperties = graphProps;
        sigInst.graphProperties(graphProperties);
    };
    this.setGraphProperty = function(graphPropertyName, graphValue) {
        this.drawingProperties[graphPropertyName] = graphValue;
        this.sigInst.drawingProperties(this.drawingProperties);
    };
    
    //vlastnosti vykreslování
    this.getDrawingProperties = function() {
        return drawingProperties;
    };
    this.getDrawingProperty = function(drawingPropertyName) {
        return drawingProperties[drawingPropertyName];
    };
    //vlastnosti grafu
    this.getGraphProperties = function() {
        return graphProperties;
    };
    this.getGraphProperty = function(graphPropertyName) {
        return graphProperties[graphPropertyName];
    };
    
    this.fullscreenMode = function(fm) {
        fullscreenMode = fm;
    };
    
    this.setNodeColor = function(number, color) {
        sigInst.iterNodes(function(n) {
            if (n.id == number) {
                n.color = color;
            }
        }).draw();
    };
    
    this.deleteNode = function(number) {
        sigInst.iterNodes(function(n) {
            if (n.id == number) {
                n.hidden = 1;
                hideNodeInfo();
            }
        }).draw();
    };
    
    //vrací node podle jeho id, tzn. čísla karty
    this.getNode = function(nodeNumber) {
        var node = null;
        sigInst.iterNodes(function(n){
            n.color = nodeColor;
            node = n;
        }, [nodeNumber]);
        return node;
    };
    
    
    this.setNotExistingNodeColor = function(nColor) {
        notExistingNodeColor = nColor;
    };
    
    //nastavitelný eventy na edit a delete karty
    this.nodeEditClick = function() {};
    this.nodeDeleteClick = function() {};
    
    /////////////////////////////////////////////////////////////////
    
    //vrací číslo karty (!!!ne id!!!)
    function onClick(e) {
        var node = getNode(e.content[0]);
    };
    
    
    
    //převede visible atributy na html netříděný seznam
    function attributesToString(attrs) {
        var div = $("<div/>");
        for(var key in attrs) {
            $(div).append($("<span/>").html(attrs[key]+"<br/>"));
        }
        return div[0].outerHTML;
    };
    
    function createButtonsHTML(node) {
        var btnContainer = $('<div/>')
                .append($("<div/>").addClass("icon-16 edit blue-hover " + (!node.attr.attributes.hidden.exists ? "disabled" : ""))
                                   .css({width: "16px", height: "16px", display: "inline-block"}))
                .append($("<div/>").addClass("icon-16 delete blue-hover " + (!node.attr.attributes.hidden.exists ? "disabled" : ""))
                                   .css({width: "16px", height: "16px", display: "inline-block"}));
        return btnContainer[0].outerHTML;
    };

    function showNodeInfo(event) {
        popUp && popUp.remove();

        var node;
        sigInst.iterNodes(function(n){
        node = n;
        },[event.content[0]]);
        popUp = $('<div/>').append(attributesToString(node["attr"]["attributes"]["visible"]))
                .append(createButtonsHTML(node))
                .attr('id','node-info')
                .css({
                    'display': 'inline-block',
                    'border-radius': 3,
                    'padding': 5,
                    'background': '#fff',
                    'color': '#000',
                    'box-shadow': '0 0 4px #666',
                    'position': 'absolute',
                    'left': node.displayX,
                    'top': node.displayY
            });
        
        $(".edit", popUp).click(function() {self.nodeEditClick(node);});
        $(".delete", popUp).click(function() {self.nodeDeleteClick(node);});
        
        $('ul',popUp).css('margin','0 0 0 20px');
        $(canvasContainer).append(popUp);
    };

    function hideNodeInfo(event) {
        popUp && popUp.remove();
        popUp = false;
    };
    
    


};



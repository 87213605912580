
$.eachComponent('.notes:not(.icon-26)', function(notes) {
    var offset = 30;
    var options = $(notes).data('options');
    var lastValue = $('textarea', dialog).val().trim();

    var save = function() {
        notes = $('textarea', dialog).val().trim();
        if(notes != lastValue) {
            $.ajax({
                type: "POST",
                url: options.save,
                data: {notes: notes}
            }).done(function(){
                lastValue = notes;
            });
        }
    };

    var load = function(callback) {
        $.ajax({
            type: "POST",
            url: options.load
        })
        .done(function(payload) {
            var notes = payload.notes || "";
            $('textarea', dialog).val(notes.trim());
            lastValue = notes.trim();
            if(callback) {
                callback(payload);
            }
        });
    };

    var interval = setInterval(function() {
        if(dialog.dialog( "isOpen" )) {
            save();
        }
    }, 5000);

    var dialog = $('.notes-dialog', notes).dialog({
        title: options.title,
        width: 960,
        position: ['center', offset],
        height: $(window).height() - (2*offset),
        autoOpen: false,
        hide: {
            effect: 'drop',
            direction: "up"
        },
        modal: true,
        resizable: true,
        show: {
            effect: 'drop',
            direction: "up"
        },
        open: function(event, ui) {
            dialog.dialog('option', 'height', $(window).height() - (2*offset));
        },
        close: function(event, ui) {
            save();
        }
    });

    // bind na tlacitko (akce load)
    $('.nav-item-icon', notes).click(function() {
        load(function(){
            dialog.dialog('open');
        });
    });

    $(window).focus(function(){
        if(dialog.dialog( "isOpen" )) {
            load();
        }
    });

});
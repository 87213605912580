(function($) {
    $.timepicker.regional['cs'] = {
        timeOnlyTitle: 'Pouze čas',
        timeText: 'Čas:',
        hourText: 'Hodiny:',
        minuteText: 'Minuty:',
        secondText: 'Sekundy:',
        millisecText: 'Milisekundy:',
        timezoneText: 'Zóna:',
        currentText: 'Nyní',
        closeText: 'Zavřít',
        timeFormat: 'HH:mm:ss',
        amNames: ['AM', 'A'],
        pmNames: ['PM', 'P'],
        isRTL: false
    };
    $.timepicker.setDefaults($.timepicker.regional['cs']);
})(jQuery);
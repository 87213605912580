$.eachComponent('.component-example', function(context) {


    // akce se provede jen nad řádky, které byly v komponentě při jejím prvním vykreslení
    $('td', context).click(function() {
        console.log('click ' + $(this).text());
    });


    // akce se provede i nad řádky, které byly přidány ajaxem
    $(context).on('click', 'td', function(){
        console.log('on: ' + $(this).text());
    });



    $(context).on('change', ':input', function() {
       $('form', context).submit();
    });
});
$.eachComponent('.contracts_customer', function(context) {
    $('input[name="legal_person"]', context).live('click', function() { // editace
        if($('input[name="id"]', context).val() !== '') {
            $.application.ajax(location.href + "&do=changeLegalPerson",  {
                id: $('input[name="id"]', context).val(), 
                ic: $('input[name="billing_address[ic]"]', context).val(),
                legal_person: $(this).is(':checked')
            });
        }
        else {  // pridani
            if($(this).is(':checked')) {
                $('input[name="billing_address[ic]"]', context).addClass('required');
                $('input[name="billing_address[ic]"]', context).attr('required', 'required');
            }
            else {
                $('input[name="billing_address[ic]"]', context).removeClass('required');
                $('input[name="billing_address[ic]"]', context).removeAttr('required');
            }
        }
    });
    
    $('input[name="billing_address[name]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address_name"]', context).val($(this).val());
        }
    });
    
    $('input[name="billing_address[address][street]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address[street]"]', context).val($(this).val());
        }
    });
    
    $('input[name="billing_address[address][city]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address[city]"]', context).val($(this).val());
        }
    });
    
    $('input[name="billing_address[address][zipcode]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address[zipcode]"]', context).val($(this).val());
        }
    });
    
    $('input[name="billing_address[address][country]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address[country]"]', context).val($(this).val());
        }
    });
    
    $('input[name="billing_address[contact][phone]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="contact[phone]"]', context).val($(this).val());
        }
    });
    
    $('input[name="billing_address[contact][gsm]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="contact[gsm]"]', context).val($(this).val());
        }
    });
    
    $('input[name="billing_address[contact][email]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="contact[email]"]', context).val($(this).val());
        }
    });
    
    $('input[name="billing_address[contact][www]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="contact[www]"]', context).val($(this).val());
        }
    });
});


function isArray(obj) {
    if (typeof obj != "undefined" && obj != null) {
        return obj.constructor == Array;
    }
    return false;
}

function countProps(obj) {
    var l = 0;
    for (p in obj) l++;
    return l;
}

/**
 * Zacyklí js na ms milisekund
 */
function sleep(ms)
{
    var dt = new Date();
    dt.setTime(dt.getTime() + ms);
    while (new Date().getTime() < dt.getTime());
}

/**
 * Funkce na jednoduché klonování objektu
 * @param object obj
 * @returns object
 */
function clone(obj) {
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
}

/**
 * Funkce zjišťuje, jestli je proměnná prázdná
 * @param object obj
 * @returns boolean
 */
function isEmpty(obj) {
    if (typeof obj == 'undefined' || obj === null || obj === '') return true;
    if (typeof obj == 'number' && isNaN(obj)) return true;
    if (obj instanceof Date && isNaN(Number(obj))) return true;
    return false;
}
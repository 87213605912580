$.eachComponent('.configurator_summarization', function(context) {
    $('input[name="send_demand"]', context).change(function() {
        if($(this).prop('checked')) {
            $('.demand_items', context).show();
        }
        else {
            $('.demand_items', context).hide();
        }
    });
    
    $('input[name="send_order"]', context).change(function() {
        if($(this).prop('checked')) {
            $('.order_items', context).show();
        }
        else {
            $('.order_items', context).hide();
        }
    });
    
    if($('input[name="send_demand"]', context).is(':checked')) {
        $('.demand_items', context).show();
    }
    else {
        $('.demand_items', context).hide();
    }
    
    if($('input[name="send_order"]', context).is(':checked')) {
        $('.order_items', context).show();
    }
    else {
        $('.order_items', context).hide();
    }
});
$.eachComponent('.firms_payments', function(context) {
    if($('input[name="same_payments_as_main_partner"]', context).is(':checked')) {
        $('.grid', context).hide();
        $('.grid-main-partner', context).show();
    }
    else {
        $('.grid', context).show();
        $('.grid-main-partner', context).hide();
    }
    
    $('input[name="same_payments_as_main_partner"]', context).change(function(event) {
        if($(this).is(':checked')) {
            $('.grid', context).hide();
            $('.grid-main-partner', context).show();
        }
        else {
            $('.grid', context).show();
            $('.grid-main-partner', context).hide();
        }
    });
});
/**
 * Inicializace aplikace
 * @author Jan Preuss
 */

$(document).ready(function() {
    $.application.init();
    //$.application.flashes.disable(); // pokud Tom nebude chtít falshes messages, tak odkomentovat

    // zakáže automatické načítání inline editoru pro včechny prvky s contenteditable=true
    CKEDITOR.disableAutoInline = true;
});

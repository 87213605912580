$.eachComponent('#contracts_agreement', function(context) {
    
    var agreement_editor_change = false;
    var edit = $("#edit", context).data('edit');
    var confirm_translation = $("#edit", context).data('translation');
    var previous_fk_texts_agreements = $('select[name="fk_texts_agreements"]', context).val();
    var previous_lang = $('select[name="lang"]', context).val();
    for (var i in CKEDITOR.instances) {
        CKEDITOR.instances[i].on('change', function() {
            agreement_editor_change = true;
        });
    }

    $('select[name="fk_texts_agreements"]', context).change(function(e){
        /*if(edit || agreement_editor_change){
            if(confirm(confirm_translation)){
                $('input[name="reload"]', context).click();
            }else{
                $(this).val(previous_fk_texts_agreements);
                e.stopPropagation();
            }
        }else{*/
            $('input[name="reload"]', context).click();
        //}
    });
    
    $('select[name="lang"]', context).change(function(e){
        /*if(edit || agreement_editor_change){
            if(confirm(confirm_translation)){
                $('input[name="reload"]', context).click();
            }else{
                $(this).val(previous_lang);
                e.stopPropagation();
            }
        }else{*/
            $('input[name="reload"]', context).click();
        //}
    });
    
    $('.print-btn:not(.disabled)', context).click(function(){
        $('input[name="print"]', context).click();
    });
    
});
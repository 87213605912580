$.eachComponent('#content-menu', function(context) {
    
    $('.active', context).each(function() {
        $(this).parents('ul').show();
        $(this).children('ul').show();
        $(this).parents('li').addClass('active');
    });
    
    $('ul.level0').show();
    
    $(context).height($('ul:visible', context).size()*29);
    
    $('ul:visible').each(function(i, element){
       var count = $('li:visible', element).size();
       if(count == 0){
           $(element).hide();
           $(context).height($('ul:visible', context).size()*29);
       }
    });

});
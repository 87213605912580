// -------------------------- FileInput --------------------------------------

$.eachComponent(".fileinput .fileinput-input .ico_right_1", function(button) {

    var options = $(button).data('upload-options');
    var fileInput = $(button).parent().parent();
    // Inicializace třídy pro nahrávání souboru drag&dropem a přes input

    if(!$(button).hasClass('disabled')){
        $.datastorage.fileserverReady(function() {
            console.datastorage('initializing fileupload js');
            $('input', button).fileupload({
                url: $.datastorage.getUrl(),
                dropZone: $(button).parent(),
                dataType: 'json',
                singleFileUploads: true,
                sequentialUploads: true,
                maxChunkSize: 0,

                always: function(e, data) {
                    if(data && data.result && !data.result.ok) {
                        $.application.flashes.add({
                            'message': 'File upload error',
                            'type': 'error'
                        });
                        console.datastorage('fileupload ERROR', data.result);
                    }
                },
                // upload na server
                submit: function(e, data) {},

                // začátek nahrávání
                start: function(e) {
                    console.datastorage('fileupload started', e);
                    //datastorage.trigger('uploadStart');
                },

                // konec nahrávání
                stop: function() {
                    console.datastorage('fileupload finished');
                    //datastorage.trigger('uploadStop');
                    //currentFolder.click();
                },

                // přidání souborů (hned se pokouší odeslat na server)
                add: function(e, data) {
                    if(data.files.length < 1) {
                        return;
                    }

                    e.preventDefault();

                    var formData = [
                        { name: "datastorage[action]", value: "save" },
                        { name: "datastorage[data][parent]", value: options.parent },
                        { name: "datastorage[data][user]", value: options.user },
                        { name: "datastorage[data][hash]", value: options.hash },
                        { name: "APC_UPLOAD_PROGRESS", value: options.APC_UPLOAD_PROGRESS }
                    ];

                    data.files.each(function(key, value) {
                        formData.push({
                            name: "datastorage[filesAttr]["+key+"][overwrite]",
                            value: true
                        });
                        // generujeme náhodný název souboru, aby se předošlo kolizím
                        formData.push({
                            name: "datastorage[filesAttr]["+key+"][rename]",
                            value: Math.random().toString(36).substr(2, 9)
                        });
                    });

                    // nastavení odesílaných dat
                    $('input', button).fileupload('option', {
                        formData: formData
                    });

                    console.datastorage('fileupload files added');

                    // odeslani souboru na server
                    data.submit();


                },

                paste: function() {
                    if($('input:focus').length > 0 || $('textarea:focus').length > 0) {

                    } else {
                        return false; // ošetření defaultní akce při paste, aby neházela chybu
                    }
                },

                done: function(e, data){
                    if(data.result && data.result.filesIDs && (data.result.ok != false)){
                        var fileID = data.result.filesIDs[0];
                        $('input.fileinput-control', fileInput).val(fileID);
                        var href = $(".fileinputadd", fileInput).attr('href');
                        href += "&fileID="+fileID;
                        $(".fileinputadd", fileInput).attr('href', href);
                        $(".fileinputadd", fileInput).click();
                        console.datastorage('fileupload done success');
                    }else{
                        $.application.flashes.add({
                            'message': 'File upload error',
                            'type': 'error'
                        });
                    }
                }
            });
        });
    }
    $(".deletefile:not(.disabled)", fileInput).click(function(){
       $(".fileinputdelete", fileInput).click();
    });
});
$.eachComponent('.partner_from_client', function(context) {
    
    $('input[name="billing_address[name]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address_name"]', context).val($(this).val());
        }
    });

    $('input[name="billing_address[address][street]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address[street]"]', context).val($(this).val());
        }
    });

    $('input[name="billing_address[address][city]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address[city]"]', context).val($(this).val());
        }
    });

    $('input[name="billing_address[address][zipcode]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address[zipcode]"]', context).val($(this).val());
        }
    });

    $('input[name="billing_address[address][country]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="address[country]"]', context).val($(this).val());
        }
    });

    $('input[name="billing_address[contact][phone]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="contact[phone]"]', context).val($(this).val());
        }
    });

    $('input[name="billing_address[contact][gsm]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="contact[gsm]"]', context).val($(this).val());
        }
    });

    $('input[name="billing_address[contact][email]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="contact[email]"]', context).val($(this).val());
        }
    });

    $('input[name="billing_address[contact][www]"]', context).bind('keyup change', function(event) {
        if($('input[name="same_contact_address"]', context).is(':checked')) {
            $('input[name="contact[www]"]', context).val($(this).val());
        }
    });
});

$.eachComponent ('#frm-filter-formFilter-firm', function (context) {
    filterList (context);
    $ (context).change ('select', function () {
        $ ('#frm-filter-formFilter-manufacturer option').each (function () {
            $ (this).show ();
        });

        filterList (context);

    });
});


function filterList (context) {
    if (typeof $ (context).data ('link') != 'undefined')
        if ($ (context).val () > 0) {
            $.get ($ (context).data ('link') + '&partner=' + $ (context).val (), function (data) {
                $ ('#frm-filter-formFilter-manufacturer option').each (function () {
                    $ (this).show ();
                    if (data.indexOf (parseInt (this.getAttribute ('value'))) == -1 && this.getAttribute ('value') != "0") {
                        $ (this).hide ();
                    }
                })
            })
        }

}
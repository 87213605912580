var activeRows = new Array();
var gridId = null;

$.eachComponent('.dataGrid', function(grid) {

    $('tr.calculation', grid).each(function(key) {
        var link = $(this).data('link');
        if (link) {
            var $that = $(this);
            $(this).prev('.dataGrid-body-row').click(function(e) {
                // nezobrazovat pokud kliknu na tlacitka
                if (!$(e.target).hasClass('dataGrid-body-button')) {
                    // zvyraznime vybrany radek
                    $(this).closest(".dataGrid").find(".dataGrid-body-row.activeRow").removeClass("activeRow");
                    $(this).addClass("activeRow");

                    // pokud jiz neni detail nacten tak nactem ajaxem, jinak jen zobrazime drive nactenej
                    if ($that.find('td').children().length == 0) {
                        $.ajax({
                            url: link
                        }).done(function(html) {
                            $that.find('td').html(html);
                            var vis = $that.is(':visible');
                            $('tr.calculation:visible', grid).hide();
                            if (!vis) {
                                $that.show();
                            }
                        });
                    } else {
                        var vis = $that.is(':visible');
                        $('tr.calculation:visible', grid).hide();
                        if (!vis) {
                            $that.show();
                        }
                    }
                }
            });
        }
    });

    // Načtení vlastností z html atributu
    var options = $.parseJSON($(grid).attr('data-datagrid-options'));

    // DblClick nad řádky,
    if (options.dblClick) {
        $(grid).on('dblclick', '.dataGrid-body-row', function() {
            $(".dataGrid-body-button" + options.dblClick, this).click();
        });
    }

    if (options.click && options.click == '.preview') {
        $(grid).on('click', '.dataGrid-body-row', function(e) {
            //e.stopPropagation();
            if ($(e.target).hasClass('dataGrid-body-button') || $(e.target).hasClass('dataGrid-body-tool')) {
                $(this).parents('tbody').find('.dataGrid-body-row').removeClass('selected');
            } else {
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected');
                } else {
                    $(this).parents('tbody').find('.dataGrid-body-row').removeClass('selected');
                    $(this).addClass('selected');
                    $('.dataGrid-body-preview .preview', this).css('top', e.pageY - $('#main').position().top).css('left', e.pageX - $('#main').position().left - 10);
                }
            }
        });
    }

    $(".dataGrid-body-button").click(function(e) {
        //e.stopPropagation();

        //načtu id gridu, kde jsem klikl
        gridId = $(this).closest(".dataGrid").closest("div").attr("id");
        //odděláme podbarvení u starého řádku
        $(this).closest(".dataGrid").find(".dataGrid-body-row.activeRow").removeClass("activeRow");
        //načtem id aktuálního řádku
        activeRows[gridId] = $(this).closest(".dataGrid-body-row").attr("id");
        //a přidáme třídu activeRow
        $ ('#' + activeRows[gridId]).addClass ("activeRow");
        return true;
    });
    $.eachComponent(".dataGrid", function(grid) {
        //načti id gridu, co se překreslil
        gridId = $(grid).closest("div").attr("id");
        if (typeof activeRows[gridId] !== 'undefined' && activeRows[gridId] !== null) {
            $('#' + activeRows[gridId]).children().addClass("activeRow");
            activeRows[gridId] = null;
        }
    });
    // Drag&Drop řazení řádků tabulky
    var helperTable = $(grid).clone();
    $('thead, tbody, tfoot', helperTable).remove();
    helperTable.append($('<tbody/>'));

    //console.log($('tbody .datagrid-tool-sort', grid).length == 0);
    if ($('tbody .datagrid-tool-sort', grid).length > 0) {
        $('tbody', grid).sortable({
            items: "tr",
            opacity: 0.85,
            placeholder: "datagrid-sortable-placeholder",
            revert: true,
            helper: function(e, row) {
                return helperTable.clone().append($(row).clone());
            },
            handle: "td.datagrid-tool-sort",
            update: function(e, ui) {
                var position = $('tbody tr', grid).index(ui.item) + 1;

                var href = $('.datagrid-tool-sort [data-href]', ui.item).data('href').replace(/position=[0-9]+/, 'position=' + position);
                $.application.ajax(href);
            },
            sort: function(e, ui) {
                ui.placeholder.height(ui.helper.height());
            }
        });
    }

    $('td.datagrid-tool-sort', grid).disableSelection();

    $(".datagrid-tool-set-default", grid).live('click', function() {
        if ($('input', this).is(':checked')) {
            $.application.ajax($('input', this).data('href'));
        }
    });

});


/*$.eachComponent('.grid-checkbox', function(check){
 $(check).change(function(){
 $.application.ajax($(check).attr('data-signal'),{},false)
 });
 });*/
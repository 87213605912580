$.eachComponent('.general-currencies', function (context) {
    $('select[name="currency"]', context).live('change', function () {
        $('input[name="reload"]').click();
    });

    if ($('input[name=main_currency]').is(':checked')) {
        $('input[name=actual_course]').val("1.0000");
        $('input[name=actual_course]').addClass("readonly");
        $('input[name=main_currency]').addClass("readonly");
        $('select[name=course_currency]').addClass("readonly");
        $('select[name=course_currency] option[value="1"]').prop('selected', true);
    } else {
        $('input[name=actual_course]').removeClass("readonly");
        $('select[name=course_currency]').removeClass("readonly");
    }

    if ($('select[name=course_currency] option:selected').val() != 1 || $('input[name=main_currency]').is(':checked')) {
        $('input[name=actual_course]').addClass("readonly");
    } else {
        $('input[name=actual_course]').removeClass("readonly");
    }

    $('input[name=main_currency]', context).live('click', function () {
        if ($(this).is(':checked')) {
            $('input[name=actual_course]').val("1.0000");
            $('input[name=actual_course]').addClass("readonly");
            $('select[name=course_currency]').addClass("readonly");
            $('select[name=course_currency] option[value="1"]').prop("selected", true);
        } else {
            $('input[name=actual_course]').removeClass("readonly");
            $('select[name=course_currency]').removeClass("readonly");
        }
    });

    $('select[name=course_currency]', context).live('change', function () {
        if ($('select[name=course_currency] option:selected').val() != 1) {
            $('input[name="reload"]').click();
            $('input[name=actual_course]').addClass("readonly");
        } else {
            $('input[name=actual_course]').removeClass("readonly");
        }
    });

    // ----------------------- Nastaveni meny ---------------

    var lang = $('#lang', context).data('lang');
    $('input[name|="short[' + lang + ']"]', context).change(function () {
        $('.currency_short', context).html($(this).val());
    });
});


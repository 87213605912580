$.eachComponent('.configurator-products-parameters', function(context) {
    $('.multiselect', context).multiselect({
        selectedList: 1,
        classes: 'custom-multiselect',
        checkAll: function(event, ui){
            $(this).multiselect("widget").find("input:checked").each(function() {
                if($(this).val() === 'by_prices') {
                    $(this).click();
                    var name = $(this).attr('name').match(/\w+_(.*)/);
                    $('#'+name[1]+' option[value='+$(this).val()+']').removeAttr('selected');
                }
            });
        },
        uncheckAll: function(event, ui){
            $(this).multiselect("widget").find("input").each(function() {
                if($(this).val() === 'by_prices') {
                    $(this).click();
                    var name = $(this).attr('name').match(/\w+_(.*)/);
                    $('#'+name[1]+' option[value='+$(this).val()+']').attr('selected','selected');
                }
            });
            $(this).multiselect("widget").find("input:checked").each(function() {
                if($(this).val() !== 'by_prices') {
                    $(this).click();
                    var name = $(this).attr('name').match(/\w+_(.*)/);
                    $('#'+name[1]+' option[value='+$(this).val()+']').removeAttr('selected');
                }
            });
        },
     });
     $('.multiselect', context).multiselect('update');
});

$.eachComponent('.custom-multiselect', function(context) {
    $('.ui-multiselect-checkboxes input[value="by_prices"]', context).change(function() {
        if($(this).is(':checked') == true) {
            $(context).find("input:checked").each(function() {
                if($(this).val() !== 'by_prices') {
                    $(this).click();
                    var name = $(this).attr('name').match(/\w+_(.*)/);
                    $('#'+name[1]+' option[value='+$(this).val()+']').removeAttr('selected');
                }
            });
        }
    });
    $('.ui-multiselect-checkboxes input[value!="by_prices"]', context).change(function() {
        if($(this).is(':checked') == true) {
            $(context).find("input:checked").each(function() {
                if($(this).val() === 'by_prices') {
                    $(this).click();
                    var name = $(this).attr('name').match(/\w+_(.*)/);
                    $('#'+name[1]+' option[value='+$(this).val()+']').removeAttr('selected');
                }
            });
        }
    });
});

$.eachComponent('.configurator-products-parameters', function(context) {
    $('select[name*="select_value"]', context).bind('change', function() {
        var element_name = $(this).attr('name');
        var matches = element_name.match(/\w+\[(\d+)\]\[(\w+)\]/);
        if (matches) {
            var id = matches[1];
            var name = matches[2];
            
            if($(this).val() == "by_prices") {
                $('input[name="parameters['+ id +'][value]"]', context).hide();
                $('.mj_'+ id, context).hide();
            }
            else {
                $('input[name="parameters['+ id +'][value]"]', context).show();
                $('.mj_'+ id, context).show();
            }
        }
    });
    
    $('select[name*="select_value"]', context).change();
});
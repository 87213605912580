/*$.eachComponent('.general-states', function(context) {
    $('input[name="default_after_copy"]', context).change(function(){
        console.log($(this).val());
        $.application.ajax(location.href + "&do=setDefaultAfterCopy",  {state_id: $(this).val()});
    });
    
    $('input[name="default_after_order"]', context).change(function(){
        console.log($(this).val());
        $.application.ajax(location.href + "&do=setDefaultAfterOrder",  {state_id: $(this).val()});
    });
    
    $('input[name="default_after_confirm_agreement"]', context).change(function(){
        console.log($(this).val());
        $.application.ajax(location.href + "&do=setDefaultAfterConfirmAgreement",  {state_id: $(this).val()});
    });
});*/

$.eachComponent('.general-states input[name="default_after_copy"]', function(context) {
    $(context).change(function(){
        console.log($(this).val());
        $.application.ajax(location.href + "&do=setDefaultAfterCopy",  {state_id: $(this).val()});
    });
});

$.eachComponent('.general-states input[name="default_after_order"]', function(context) {
    $(context).change(function(){
        console.log($(this).val());
        $.application.ajax(location.href + "&do=setDefaultAfterOrder",  {state_id: $(this).val()});
    });
});

$.eachComponent('.general-states input[name="default_after_confirm_agreement"]', function(context) {
    $(context).change(function(){
        console.log($(this).val());
        $.application.ajax(location.href + "&do=setDefaultAfterConfirmAgreement",  {state_id: $(this).val()});
    });
});

$.eachComponent('.general-states input[name="export_api"]', function(context) {
    $(context).change(function(){
        console.log($(this).val());
        $.application.ajax(location.href + "&do=setExportApi",  {state_id: $(this).val()});
    });
});
$.eachComponent(".discounts_checkbox", function(checkbox) {
    if (!$(checkbox).is(":checked")) {
        $(checkbox).parent().nextAll(":lt(3)").children().each(function() {
            $(this).attr("disabled", "disabled");
        });    
    }
    else {
        $(checkbox).parent().nextAll(":lt(3)").children().each(function() {
            if (!$(this).hasClass("cannot_enable")) {
                $(this).removeAttr("disabled");
            }
            else {
                $(this).attr("disabled", "disabled");
            }
        });
    }
    
    $(checkbox).change(function() {
        if (!$(this).is(":checked")) {
            $(this).parent().nextAll(":lt(3)").children().attr("disabled", "disabled");      
        }
        else {
            $(this).parent().nextAll(":lt(3)").children().each(function() {
                if (!$(this).hasClass("cannot_enable")) {
                    $(this).removeAttr("disabled");
                }
            })
        }
    });
});



/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


(function($) {

$.fn.extend({
    toString: function() {
        if(this.size() > 0) {
            var div  = $('<div></div>');
            return div.append(this.clone()).html();
        }
        return '';
    }
});

})(jQuery);
// Langpanel
$().ready(function() {
    $('html').bind('click dblclick contextmenu', function(){
        $('#langpanel').hide();
    });
});

$.eachComponent('header', function(context) {

    $(context).on('click','#nav-language',function(event){
        event.stopPropagation();
        $('#langpanel').toggle();
    });

});
$.eachComponent('#tree-viewer-context', function(context) {

   $(context).on('click', '.node', function() {
       var node_id = $(this).attr("id").replace("node-","");
       $("#group-"+node_id, context).toggle();
   });

   $(context).on('change', '.tree-radio', function() {
      var p = $(this).attr("id").split("-");
      $('#group-'+p[1], context).find('.tree-radio').removeAttr('checked');
      $('#group-'+p[1], context).find('[value="'+p[2]+'"]').attr('checked', true);
   });
});


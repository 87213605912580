
$.eachComponent('.list-filter', function(context) {

    // odeslání formuláře enterem nad inputy
    $("form:not(.noajax) input", context).bind('keypress', function (e) {
        if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
            e.preventDefault();
            e.stopPropagation();
            $('input.submit:first', context).click();
            return false;
        } else {
            return true;
        }
    });

    console.log($("form:not(.noajax) input", context).val());





});

$.eachComponent(".relatedSelect", function(select) {
    var relatedOptions = $(select).data('related-options');
    var relatedParent = null;
    var relatedItems = [];
    var options = [];
    var value = $(select).val();

    $('option', select).each(function() {
        options.push($(this));
    });

    if (relatedOptions['relatedParentId'] != null) {
        relatedParent = $(relatedOptions['relatedParentId']);
    }
    if (relatedOptions['relatedItems'] != null) {
        relatedItems = relatedOptions['relatedItems'];
    }

    if (relatedParent != null) {
        var items = relatedItems[$(relatedParent).val()];
        var newOptions = [];
        if (items.length > 0) {
            for(var io = 0; io < options.length; io++) {
                for (var i = 0; i < items.length; i++) {
                    if ($(options[io]).val() == items[i]) {
                        newOptions.push(options[io]);
                        break;
                    }
                }
            }
        }

        $(select).children().remove();
        var setFirst = true;
        for(var i = 0; i < newOptions.length; i++) {
            $(select).append(newOptions[i]);
            if ($(newOptions[i]).val() == value) {
                setFirst = false;
            }
        }
        if (setFirst) {
            $(select).val($(newOptions[0]).val());
        } else {
            $(select).val(value);
        }

        $(relatedParent).change(function() {
            var items = relatedItems[$(this).val()];
            var newOptions = [];
            if (items.length > 0) {
                for(var io = 0; io < options.length; io++) {
                    for (var i = 0; i < items.length; i++) {
                        if ($(options[io]).val() == items[i]) {
                            newOptions.push(options[io]);
                            break;
                        }
                    }
                }
            }

            $(select).children().remove();
            var setFirst = true;
            for(var i = 0; i < newOptions.length; i++) {
                $(select).append(newOptions[i]);
                if ($(newOptions[i]).val() == value) {
                    setFirst = false;
                }
            }
            if (setFirst) {
                $(select).val($(newOptions[0]).val());
            } else {
                $(select).val(value);
            }
        });
    }
});
$.eachComponent(".formulainput, .formulainvalidinput", function(formulainput) {
    var input = $(".formulainput-input", formulainput);
    var hidden = $(".formulainput-hidden", formulainput);

    var products = $(hidden).data('products') || {};

    var variablesPatern = /\$#([^\$]+)#\$/i;
    var productsPatern = /@([^@]+)@/i;

    // překopírování výchozí hodnoty do divu
    input.html( hidden.val().replaceCallback(variablesPatern, function(id) {
        var button = $("button[value=\"" + id + "\"]", formulainput);
        if(button.size()) {
            var param = $("<input/>", {
                     id: button.val(),
                     value: button.attr('title'),
                     width: (button.attr('title').length * 6) + 5, //button.width(),
                     disabled: true
                }).addClass("param");
            return "&nbsp;" + param[0].outerHTML + "&nbsp;";
        }
        else {
            return "";
        }
    }).replaceCallback(productsPatern, function(id) {
            if (String(id).match(productsPatern)) {
                var value = String(id).match(productsPatern)[1];
                value = products[value] || value;
                var param = $("<input/>", {
                    id: id,
                    value: value,
                    width: (value.length * 6) + 5,
                    disabled: true
                }).addClass("param");
                return "&nbsp;" + param[0].outerHTML + "&nbsp;";
            } else {
                return "";
            }
        }).replace(/(&nbsp;)+/gi, '&nbsp;') + "<br>" );

    // při ztrátě focusu se přepíše hodnota do hidden pole
    input.focusout(function() {
        hidden.val("");
        for(var i=0;i<$(this)[0].childNodes.length;i++) {
            if ($(this)[0].childNodes[i].nodeType === 1) {
                hidden.val(hidden.val() + $(this)[0].childNodes[i].id);
            }
            else {
                hidden.val(hidden.val() + $(this)[0].childNodes[i].data);
            }
			if($(formulainput).hasClass("formulainput")) {
				hidden.val(hidden.val().replace(/\s+/g, ""));
			}
        }
    });

    // Parametr typu výběr - kliknuti na tlacitko pro sbaleni/rozbaleni podpolozek
    $('.formulainput-params button.btn-main', formulainput).click(function() {
        $(this).siblings().toggle();
        //$(input).focus();
    });

    $('.formulainput-params button.btn-variable:not(.btn-main)', formulainput).click(function() {
        try {
            $(input).focus();
            var param = $("<input/>", {
                 id: $(this).val(),
                 value: $(this).attr('title'),
                 width: ($(this).attr('title').length * 5) + 5, //$(this).width(),
                 disabled: true
            }).addClass("param");
            pasteHtmlAtCaret(param[0].outerHTML);
        }
        finally {
            var parent = $(this).parent();
            if(parent.is('.div_selection')) {
                $('button.btn-variable:not(.btn-main)', parent).hide();
            }
            return false;
        }
    });

    // -------------- Zobrazení výběru proměnných ------------------------------
    $('.variable:not(disabled)', formulainput).click(function() {
        $('#variabless').empty();
        var clon = $('.formula-variables-container-picker', formulainput).clone(true);
        $('.formula-variables-container-picker', formulainput).show();
        var pos = $('.formula-variables-container-picker', formulainput).offset();
        $('.formula-variables-container-picker', formulainput).hide();
        $(clon).appendTo('#variabless');
        $(clon).show();
        $(clon).css({
            position: 'absolute',
            top: (pos.top) - 1,
            left: (pos.left) + 25,
            zIndex: 1000,
            //width: 190
        });
        $(input).focus();
    });
    $('.search:not(disabled)', formulainput).click(function() {
        var values = {};
        $.each($(this).closest('form').serializeArray(), function() {
            if (this.name != 'do') {
                values[this.name] = this.value;
            }
        });

        $.application.ajax($(this).data('href'), values);
    });
    $('button.ui-dialog-titlebar-close', formulainput).click(function(e){
        $('#variabless').empty();
        e.stopPropagation();
        e.preventDefault();
    });
    // -------------------------------------------------------------------------
});



function pasteHtmlAtCaret(html) {
    var sel, range;
    if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            var el = document.createElement("div");
            el.innerHTML = "&nbsp;" + html + "&nbsp;";
            var frag = document.createDocumentFragment(), node, lastNode;
            while ( (node = el.firstChild) ) {
                lastNode = frag.appendChild(node);
            }
            range.insertNode(frag);
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    } else if (document.selection && document.selection.type != "Control") {
        // IE < 9
        document.selection.createRange().pasteHTML(html);
    }
}

function setCaretPos(startNodeIndex, endNodeIndex, start, end){
	var range = document.createRange();
	var editableContainer = document.getElementById(containerId);
	range.setStart(editableContainer.childNodes[startNodeIndex].firstChild,start);
	range.setEnd(editableContainer.childNodes[endNodeIndex].firstChild,end);
	var selection = window.getSelection();
	selection.addRange(range);
    }

function getCaretPosition() {
    var userSelection;
    if (window.getSelection) {
            userSelection = window.getSelection();
    }
    return userSelection.focusOffset;
}




$.eachComponent('#frm-properties-form-can_view_contracts_other_firms', function(context) {
    console.log($(context).prop( "checked" ));

    if(!$(context).prop( "checked" ))
        $('.can_see_others_states.option-group').hide();
    $(context).change(function(){
        console.log('zmenil');
        $('.can_see_others_states.option-group').toggle();
    })

});

$.eachComponent ('.contracts-calculation-products', function (context) {
    $ ('#dialog-confirm-change-state').dialog ({
        width: 340,
        minHeight: 50,
        autoOpen: false,
        modal: true
    });

    $ ('input[name="date"]', context).change (function () {
        $ ('input[name="reloadDate"]').click ();
    });
    $ ('select[name="fk_currencies"]', context).change (function () {
        $ ('input[name="reloadCurrencies"]').click ();
    });
    $ ('select[name="fk_dph_rate_values"]', context).change (function () {
        $ ('input[name="reloadDph"]').click ();
    });
    $ ('input[name="discount"], select[name="discount_type"], input[name="deposit_of_price_val"], select[name="deposit_of_price_type"]', context).live ('change', function () {
        if ($ (this).val () != "") {
            $ ('input[name="reloadSummarization"]').click ();
        }
    });

    $ ('.submit', '#dialog-confirm-change-state').click (function (event) {
        event.preventDefault ();
        event.stopPropagation ();
        if ($ (this).hasClass ('yes')) {
            $ ('.general_states', context).data ('old-value', $ ('.general_states', context).val ());
            $ ('#dialog-confirm-change-state').dialog ('close');
            $.application.ajax (location.href + '&do=ChangeState', {fk_general_states: $ ('.general_states', context).val ()});
        }
        else {
            $ ('.general_states', context).val ($ ('.general_states', context).data ('old-value'));
            $ ('#dialog-confirm-change-state').dialog ('close');
        }
    });

    $ (".quantity input", context).bind ('keypress', function (e) {
        if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13)) {
            e.preventDefault ();
            e.stopPropagation ();
            $ ('input[name="quick_insert[submit_insert]"]', context).click ();
            return false;
        } else {
            return true;
        }
    });

    $ ('#calculation_buttons #list-paging-wizard, #calculation_buttons #list-paging-print, #calculation_buttons #list-paging-link, #calculation_buttons #list-paging-send, #calculation_buttons #list-paging-discount, #calculation_buttons #list-paging-calculator', context).click (function (event) {
        $ ('#content-footer input[name="save"]').click ();
    });
});

$.eachComponent ('.contracts-calculation-products .general_states', function (context) {
    $ (context).data ('old-value', $ (context).val ());

    if ($ (context).parents ('form').find ('.submit.save').length > 0) {
        $ (context).change (function (event) {
            if ($ (event.target).data ('confirm-change')) {
                var descriptions = $ (event.target).data ('descriptions');
                $ ('.description', '#dialog-confirm-change-state').html (descriptions[$ (event.target).val ()]);
                $ ('#dialog-confirm-change-state').dialog ('open');
            }
        });
    }
});

$.eachComponent ('.calculation_edit_product_dialog', function (context) {
    $ ('input[name="discount"]', context).change (function () {
        var dph = $ ('input[name="dph_value"]', context).val ();
        var discount = $ (this).val ();
        var quantity = $ ('input[name="quantity"]', context).val ();

        var price = $ ('input[name=price_base_without_dph]', context).val () * (1 - discount / 100);
        $ ('input[name="price_unit_without_dph"]', context).val (price.toFixed (2));
        price = price * quantity;
        $ ('input[name="price_without_dph"]', context).val (price.toFixed (2));

        price = price * (1 + dph / 100);
        $ ('input[name="price_with_dph"]', context).val (price.toFixed (2));
    });

    $ ('input[name="quantity"]', context).change (function () {
        var dph = $ ('input[name="dph_value"]', context).val ();
        var discount = $ ('input[name="discount"]', context).val ();
        var quantity = $ (this).val ();

        var price = $ ('input[name=price_unit_without_dph]', context).val ();
        //$('input[name="price_base_without_dph"]', context).val(price.toFixed(2));
        price = price * quantity;
        $ ('input[name="price_without_dph"]', context).val (price.toFixed (2));

        price = price * (1 + dph / 100);
        $ ('input[name="price_with_dph"]', context).val (price.toFixed (2));
    });

    $ ('input[name=price_base_without_dph]', context).change (function () {
        var dph = $ ('input[name="dph_value"]', context).val ();
        var discount = $ ('input[name="discount"]', context).val ();
        var quantity = $ ('input[name="quantity"]', context).val ();

        var price = $ (this).val () * (1 - discount / 100);
        //price = (price / 100) * discount;
        $ ('input[name="price_unit_without_dph"]', context).val (price.toFixed (2));
        price = price * quantity;
        $ ('input[name="price_without_dph"]', context).val (price.toFixed (2));

        price = price * (1 + dph / 100);
        $ ('input[name="price_with_dph"]', context).val (price.toFixed (2));
    });

    $ ('input[name="price_unit_without_dph"]', context).change (function () {
        var dph = $ ('input[name="dph_value"]', context).val ();
        var discount = $ ('input[name="discount"]', context).val ();
        var quantity = $ ('input[name="quantity"]', context).val ();

        var price = $ (this).val () * quantity;
        $ ('input[name="price_without_dph"]', context).val (price.toFixed (2));
        price = price * (1 + dph / 100);
        $ ('input[name="price_with_dph"]', context).val (price.toFixed (2));

    });

    $ ('input[name="price_without_dph"]', context).change (function () {
        var dph = $ ('input[name="dph_value"]', context).val ();
        var discount = $ ('input[name="discount"]', context).val ();
        var quantity = $ ('input[name="quantity"]', context).val ();

        var price = $ (this).val () * (1 + dph / 100);
        $ ('input[name="price_with_dph"]', context).val (price.toFixed (2));
    });

    $ ('input[name="price_with_dph"]', context).change (function () {
        var dph = $ ('input[name="dph_value"]', context).val ();
        var discount = $ ('input[name="discount"]', context).val ();
        var quantity = $ ('input[name="quantity"]', context).val ();

        var price = $ (this).val () / (1 + dph / 100);
        $ ('input[name="price_without_dph"]', context).val (price.toFixed (2));
    });

});

// Vícepráce
$.eachComponent ('.calculation_aditional_work_dialog', function (context) {
    $ ('input[name="discount"], input[name="quantity"], input[name=price_unit]', context).change (function () {
        var dph = Number ($ ('input[name="dph_val"]', context).val ());
        var discount = Number ($ ('input[name="discount"]', context).val ());
        var quantity = Number ($ ('input[name="quantity"]', context).val ());
        var price_unit = Number ($ ('input[name="price_unit"]', context).val ());

        var price_without_dph = quantity * price_unit * (1 - discount / 100);
        $ ('input[name="price_without_dph"]', context).val (price_without_dph.toFixed (2));
        var price_with_dph = price_without_dph * (1 + dph / 100);
        $ ('input[name="price_with_dph"]', context).val (price_with_dph.toFixed (2));
    });
});

//Porvrzení kalkulace

$.eachComponent ('.datagrid-column-center .checkbox-confirm', function (context) {
    $ (context).change(function () {
        var link = $ (context).data ('link') + '&calculation=' + $ (context).data('id')+ '&value=' + $ (context).prop('checked').toString();
        console.log(link);
        $.get (link, function (data) {
        });
    });
});



/*
 * Objekty a funkce pro práci s datovým úložištěm
 */

/**
 * Stavy datového úložiště
 */
var DSSTATE = {
    INIT: 0,
    FILESERVER_CONNECTING: 1,
    FILESERVER_CONNECTED: 2,
    APPLET_STARTING: 3,
    APPLET_STARTED: 4
};

/**
 * Datové úložiště
 */
(function($) {
    $.extend({
        datastorage: {
            options: {},
            state: DSSTATE.INIT,
            appletInstance: null,

            // rozhrani pro konumikaci s appletem
            applet: {
                prePasteFile: function(id) { return $.datastorage.appletInstance.prePasteFile(id); },
                pasteFile: function(id, parentFolder, apcHash, attributes) { return $.datastorage.appletInstance.pasteFile(id, $.datastorage.getUrl(), parentFolder, apcHash, attributes); },
                copyFile: function(id, files) { return $.datastorage.appletInstance.copyFile(id, files); },
                emptyClipboard: function() { return $.datastorage.appletInstance.emptyClipboard(); },
                openFile: function(id, fileUrl, filename, fileId, fileHash, parent, readonly) { return $.datastorage.appletInstance.openFile(id, fileUrl, filename, fileId, fileHash, parent); },
                runFileReadOnlyJS: function(fileId) {},
                runFileInAltEditorJS: function(fileId, editorId) {},
                closeAndDeleteFileJS: function(fileId) {},
                checkForUpdates: function() { return $.datastorage.appletInstance.checkForUpdates($.datastorage.getUrl()); },
                setEditors: function(editorsXML) { return $.datastorage.appletInstance.setEditors(editorsXML); },
                setUserInfo: function(userHash, userId, url) { return $.datastorage.appletInstance.setUserInfo(userHash, userId, url); }
            },

            fileserverReadyQueue: [],
            fileserverReady: function(callback) {
                // funkci uložím do fronty
                $.datastorage.fileserverReadyQueue.push(callback);
                // pokud fileserver běží, provedu vše co je ve frontě
                if($.datastorage.state >= DSSTATE.FILESERVER_CONNECTED) {
                    while((callback = $.datastorage.fileserverReadyQueue.shift()) !== undefined) {
                        callback();
                    }
                }
                // pokud jsem první, pustím inicializaci fileserveru
                else if($.datastorage.state < DSSTATE.FILESERVER_CONNECTING) {
                    $.datastorage.fileserverInit();
                }
            },

            fileserverInit: function() {
                if($.datastorage.options.urls && $.datastorage.options.urls.length > 0) {
                    // Pokus o připojení k fileserveru
                    $.datastorage.state = DSSTATE.FILESERVER_CONNECTING;
                    console.datastorage('connecting to fileserver');
                    var xhrs = [];
                    $.datastorage.options.urls.each(function(key, value) {
                        xhrs.push($.getJSON(value, {
                            'datastorage[action]': 'serverInfo'
                        }, function(data) {
                            if(data && data.ok) {
                                xhrs.each(function(i, xhr){
                                    xhr.abort();
                                }); // ukoncime ostatni pozadavky
                                $.datastorage.options.fileserverUrl = value;
                                $.datastorage.options = jQuery.extend($.datastorage.options, data.options);
                                $.datastorage.state = DSSTATE.FILESERVER_CONNECTED;
                                console.datastorage('connected to fileserver:', value);
                                $.datastorage.fileserverReady(function(){});
                            }
                        }));
                    });
                }
            },

            appletReadyQueue: [],
            appletReady: function(callback) {
                // funkci uložím do fronty
                $.datastorage.appletReadyQueue.push(callback);
                // pokud applet běží, provedu vše co je ve frontě
                if($.datastorage.state >= DSSTATE.APPLET_STARTED) {
                    while((callback = $.datastorage.appletReadyQueue.shift()) !== undefined) {
                        callback();
                    }
                }
                // pokud jsem první, pustím applet
                else if($.datastorage.state < DSSTATE.APPLET_STARTING) {
                    // ale jen pokud už běží fileserver
                    $.datastorage.fileserverReady($.datastorage.appletInit);
                }
                
                
                
                
            },

            appletInit: function() {
                if($.datastorage.appletInstance == null) {
                    console.datastorage('applet initialization');
                    $.datastorage.state = DSSTATE.APPLET_STARTING;
                    // kontrola, zda je nainstalovana java
                    //    if($.browser.java)
                    if (navigator.javaEnabled()) {
                        console.datastorage('appending applet to DOM');
                        var appletUrl = $.datastorage.options.appletFiles.applet.replace(/^(.*\/)[^\/]+$/, '$1');
                        var appletFile = $.datastorage.options.appletFiles.applet.replace(/^.*\/([^\/]+)$/, '$1');
                        var appletInstance = $('<applet id="datastorage-applet" class="applet" name="datastorageapplet" codebase="' + appletUrl + '" archive="' + appletFile + '" code="adaxetdsapplet/DsApplet.class" mayscript></applet>');
                        appletInstance.appendTo('.datastorage:first');
                        $.datastorage.appletInstance = document.datastorageapplet;
                    } else {
                        alert('Nebyl zjištěn plugin Java, datové úložiště bude fungovat v omezeném módu.\nZkontrolujte, že máte Javu nainstalovanou nebo že je Java ve Vašem prohlížeči povolena.');
                    }
                }
            },

            getUrl: function() {
                return ($.datastorage.options && $.datastorage.options.fileserverUrl) ? $.datastorage.options.fileserverUrl : false;
            },

            getDeamonUrl: function() {
                return ($.datastorage.options && $.datastorage.options.appletFiles.deamon) ? $.datastorage.options.appletFiles.deamon : false;
            }
        }

    });
})(jQuery);


/**
 * Načtení nastavení ze zdrojáku
 */
$.eachComponent('.datastorage', function(datastorage) {
    $.datastorage.options = $(datastorage).data('options');
    $.datastorage.fileserverReady(function(){ });
});


/**
 * Logovani do konzoly
 */
console.datastorage = function() {
    Array.prototype.unshift.call(arguments, 'DATASTORAGE>');
    console.debug.apply(this, arguments);
}



/**
 * Funkce, které volá Java
 */

function getLocation()
{
    var url = $.datastorage.getDeamonUrl()
    console.datastorage('get deamon location:', url);
    return url;
}

function dsAppletReady()
{
    $.datastorage.state = DSSTATE.APPLET_STARTED;
    console.datastorage("applet started");
    $.datastorage.appletReady(function() {
        console.datastorage('setting applet user info');
        console.datastorage($.datastorage.options.userHash);
        console.datastorage($.datastorage.options.userId);
        console.datastorage($.datastorage.getUrl());
        $.datastorage.applet.setUserInfo($.datastorage.options.userHash, $.datastorage.options.userId, $.datastorage.getUrl());
        console.datastorage('setting applet editors');
        $.ajax({
            url: $.datastorage.options.baseUrl + '/datastorage/unsecured/supportedappsxml',
            type: "POST",
            dataType: "json",
            async: false,
            success: function(data) { if(data && data.error && data.error.number <= 0) { $.datastorage.applet.setEditors(data.xml); } }
        });
        
        //při zavření adaxetu musíme pohlídat, jestli nejsou otevřený soubory
        $(window).bind("beforeunload", function() {
            $.ajax({
                url: $.datastorage.options.baseUrl + '/datastorage/unsecured/isAnyFileOpened',
                type: "POST",
                dataType: "json",
                async: false,
                data: {'user' : $.datastorage.options.userId },
                success: function(data) { 
                    if(data && data.error && data.error.number <= 0) { 
                        if (data.ok == true) {
                            $.ajax({
                                url: $.datastorage.options.baseUrl + '/datastorage/unsecured/unlockFiles',
                                type: "POST",
                                dataType: "json",
                                data: {'user' : $.datastorage.options.userId },
                                success: function(data) { 
                                    if(data && data.error && data.error.number <= 0) { 
                                        console.datastorage('all files unlocked');
                                    } 
                                }
                            });

                            alert("Máte otevřen minimálně jeden soubor z datového uložiště!\n\
                                   Soubory nelze po uzavření systému dále editovat!");
                        }
                        console.datastorage('all files unlocked');
                    } 
                }
            });
                    
        }); 
        
        
        
        

        console.datastorage('run checkForUpdates');
        setInterval($.datastorage.applet.checkForUpdates, 1000);
    });
}

function dsErrorMsg(msg) {
    $.application.flashes.add({ 'message': msg, 'type': 'error' });
}

function dsOpenStart(id)
{
    $.application.progress.show('Probíhá otevírání souboru');
}

function dsSolveUnsupportedEditor(id, fileId, editors)
{
    console.datastorage("Not implemented", arguments.callee, arguments);
}


function dsOpenStop(id)
{
    $.application.progress.hide();
}

function dsCopyStart(id) {
    $.application.progress.show('Probíhá kopírování souboru do schránky');
}

function dsCopyStop(id) {
    $.application.progress.hide();
}

function dsPreUploadStart(id) {
    $.application.progress.show('Probíhá příprava kopírování souboru ze schránky');
}

function dsPreUploadStop(id, files) {
    $.application.progress.hide();

    files = $.makeArray(files);
    var attributes = [];
    $.each(files, function(i, file) {
        attributes.push([true, "", ""]); // momentálně vždy přepisujeme soubor
    });
    var options = $('.datastorage-explorer', $('#' + id)).data('datastorage-explorer-options');
    $.datastorage.applet.pasteFile(id, options.parent, options.apcHash, attributes);
}

function dsUploadStart(id) {
    $.application.progress.show('Probíhá kopírování souboru ze schránky');
}

function dsUploadStop(id) {
    $.application.progress.hide();
    $('.datastorage-explorer .head .head-button.refresh').parent().click();
}

function dsUpdateDone() {
    $('.datastorage-explorer .head .head-button.refresh').parent().click();
}

function dsLog(msg)
{
    console.datastorage("Not implemented", arguments.callee, arguments);
}


function dsDrop() {
    console.datastorage("Not implemented", arguments.callee, arguments);
}

function dsDragExit() {
    console.datastorage("Not implemented", arguments.callee, arguments);
}

function dsUnlockFile(id) 
{
    console.datastorage('Trying to unlock file id=' + id);
     $.ajax({
            url: $.datastorage.options.baseUrl + '/datastorage/unsecured/unlockFile',
            type: "POST",
            dataType: "json",
            data: {
                'id' : id, 
                'user' : $.datastorage.options.userId 
            },
            async: false,
            success: function(data) { 
                if(data && data.error && data.error.number !== 0) { 
                    console.datastorage("Couldn't unlock file!"); 
                }
                else {
                    dsUpdateDone();
                }
            }
        });
}

function dsLockFile(id) 
{
    console.datastorage('Trying to lock file id=' + id);
     $.ajax({
            url: $.datastorage.options.baseUrl + '/datastorage/unsecured/lockFile',
            type: "POST",
            dataType: "json",
            data: {
                'id' : id, 
                'user' : $.datastorage.options.userId 
            },
            async: false,
            success: function(data) 
            {  console.datastorage(data);
                if(data && data.error && data.error.number !== 0) 
                { console.datastorage("Couldn't lock file!");} 
                else 
                {   dsUpdateDone();  }
            }
        });
} 
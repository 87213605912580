$.eachComponent('.configurator-products-bulk-edit', function(context) {
    $('input[name="change_active"]', context).change(function() {
        var checkbox = this;
        $('.change_active', context).find('input').each(function() {
            if($(checkbox).is(':checked')) {
                $(this).removeAttr('disabled');
            }
            else {
                $(this).attr('disabled', 'disabled');
            }
        });
    });
    
    $('input[name="change_groups"]', context).change(function() {
        var checkbox = this;
        $('.change_groups', context).find('input').each(function() {
            if($(checkbox).is(':checked')) {
                $(this).removeAttr('disabled');
            }
            else {
                $(this).attr('disabled', 'disabled');
            }
        });
    });
    
    $('input[name="change_offer"]', context).change(function() {
        var checkbox = this;
        $('.change_offer', context).find('select').each(function() {
            if($(checkbox).is(':checked')) {
                $(this).removeAttr('disabled');
            }
            else {
                $(this).attr('disabled', 'disabled');
            }
        });
    });
    
    $('input[name="change_fk_users"]', context).change(function() {
        var checkbox = this;
        $('.change_fk_users', context).find('select').each(function() {
            if($(checkbox).is(':checked')) {
                $(this).removeAttr('disabled');
            }
            else {
                $(this).attr('disabled', 'disabled');
            }
        });
    });
    
    $('input[name="change_apply_discounts"]', context).change(function() {
        var checkbox = this;
        $('.change_apply_discounts', context).find('input').each(function() {
            if($(checkbox).is(':checked')) {
                $(this).removeAttr('disabled');
            }
            else {
                $(this).attr('disabled', 'disabled');
            }
        });
    });
    
    $('input[name="change_add_parameters"]', context).change(function() {
        var checkbox = this;
        $('.change_add_parameters', context).find('input, select, button').each(function() {
            if($(checkbox).is(':checked')) {
                $(this).removeAttr('disabled');
                $(this).removeClass('readonly');
            }
            else {
                $(this).attr('disabled', 'disabled');
                $(this).addClass('readonly');
            }
        });
    });
    
    $('input[name="change_change_parameters"]', context).change(function() {
        var checkbox = this;
        $('.change_change_parameters', context).find('input, select, button').each(function() {
            if($(checkbox).is(':checked')) {
                $(this).removeAttr('disabled');
                $(this).removeClass('readonly');
            }
            else {
                $(this).attr('disabled', 'disabled');
                $(this).addClass('readonly');
            }
        });
    });
    
    $('input[name="change_discounts"]', context).change(function() {
        var checkbox = this;
        $('.change_discounts', context).find('input:checkbox').each(function() {
            if($(checkbox).is(':checked')) {
                $(this).removeAttr('disabled');
            }
            else {
                $(this).attr('disabled', 'disabled');
            }
        });
        $('.change_discounts', context).find('input:text').each(function() {
            if($(checkbox).is(':checked')) {
                $(this).removeClass('readonly');
            }
            else {
                $(this).addClass('readonly');
            }
        });
    });
    
    $('input[name="change_active"]', context).change();
    $('input[name="change_groups"]', context).change();
    $('input[name="change_offer"]', context).change();
    $('input[name="change_fk_users"]', context).change();
    $('input[name="change_apply_discounts"]', context).change();
    $('input[name="change_add_parameters"]', context).change();
    $('input[name="change_change_parameters"]', context).change();
    $('input[name="change_discounts"]', context).change();
    $('select[name*="select_value"]', context).change();
});

$.eachComponent('.change_add_parameters', function(context) {
    $('select[name*="select_value"]', context).change(function() {
        var element_name = $(this).attr('name');
        var matches = element_name.match(/\w+\[(\d+)\]\[(\w+)\]/);
        if (matches) {
            var id = matches[1];
            var name = matches[2];
            if($(this).val() == "by_prices") {
                $('input[name*="add_parameters['+ id +'][value]"]', context).hide();
                $('.mj_'+ id, context).hide();
            }
            else {
                $('input[name*="add_parameters['+ id +'][value]"]', context).show();
                $('.mj_'+ id, context).show();
            }
        }
    });
    
    $('select[name*="select_value"]', context).change();
});

$.eachComponent('.change_change_parameters', function(context) {
    $('select[name*="select_value"]', context).change(function() {
        var element_name = $(this).attr('name');
        var matches = element_name.match(/\w+\[(\d+)\]\[(\w+)\]/);
        if (matches) {
            var id = matches[1];
            var name = matches[2];
            if($(this).val() == "by_prices") {
                $('input[name*="change_parameters['+ id +'][value]"]', context).hide();
                $('.mj_'+ id, context).hide();
            }
            else {
                $('input[name*="change_parameters['+ id +'][value]"]', context).show();
                $('.mj_'+ id, context).show();
            }
        }
    });
    
    $('select[name*="select_value"]', context).change();
});
$.eachComponent('.contracts-general', function (context) {
    $('#dialog-confirm-change-contract-state').dialog({
        width: 340,
        minHeight: 50,
        autoOpen: false,
        modal: true,
        closeOnEscape: false,
        dialogClass: 'noclose'
    });

    $('#dialog-condition-state').dialog({
        width: 340,
        minHeight: 50,
        autoOpen: false,
        modal: true,
        closeOnEscape: false,
        dialogClass: 'noclose'
    });

    $('.general_states', context).data('old-value', $('.general_states', context).val());

    if ($(context).parent('form').find('.submit.save').length > 0) {
        $('.general_states', context).change(function () {
            // kontrola podminky stavu
            $.application.ajax(location.href + '&do=CheckCondition', {id_new_state: $(this).val(), id_old_state: $('.general_states', context).data('old-value')});
        });
    }

    // ulozeni pri potvrzeni zmeny stavu
    $('.submit', '#dialog-confirm-change-contract-state').click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        if ($(this).hasClass('yes')) {
            $('#dialog-confirm-change-contract-state').dialog('close');
            $(context).parent('form').find('.submit.save').click();
        }
        else {
            $('#dialog-confirm-change-contract-state').dialog('close');
            $('.general_states', context).val($('.general_states', context).data('old-value'));
        }
    });

    // zobrazeni nesplnene podminky
    if ($.application.condition && !$.application.condition.result) {
        $('#dialog-condition-state').dialog('open');
        $('.description', '#dialog-condition-state').html($.application.condition.text);
    }

    // zobrazeni dialogu s potvrzenim zmeny stavu
    if ($.application.condition && $.application.condition.showConfirm) {
        if ($('.general_states', context).data('confirm-change')) {
            var descriptions = $('.general_states', context).data('descriptions');
            $('.description', '#dialog-confirm-change-contract-state').html(descriptions[$.application.condition.newValue]);
            $('.general_states', context).data('old-value', $.application.condition.oldValue);
            $('#dialog-confirm-change-contract-state').dialog('open');
        }
    }

    $('.control', context).change(function (e) {
        $('input[name="control"]', context).val($(this).is(':checked'));
    });
});
/**
 * TextInputCross - prvek: kliknutí na křížek
 */
$.eachComponent('.crossinput', function (context) {
    $('.cross', context).not('.disabled').click(function () {
        $('.cross_input_submit', context).click();
    });
});

/**
 * Input s kalendářem na výběr data
 */
$.eachComponent('.dateinput', function (context) {
    // Při kliknutí na ikonu se nastaví focus inputu
    $('.calendar', context).not('.disabled').click(function () {
        $('input', context).focus();
    });

    $('input', context).datepicker();

});

$.eachComponent('.accessories-mipl', function (context) {
    $('.add', context).not('.disabled').click(function () {
        $('.item_add_input_submit', context).click();
    })
    $('.remove', context).not('.disabled').click(function () {
        $('.item_remove_input_submit', context).click();
    })
});

/**
 * Input navrhující možnosti
 */
$.eachComponent('.autocomplete', function (context) {
    $(context).not('input:disabled').autocomplete({
        source: function (request, response) {
            $.ajax({
                url: $(context).attr('data-signal-search') + request.term,
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            id: item.id,
                            label: item.label,
                            value: item.value,
                            column: item.column
                        }
                    }));
                }
            });
        },
        select: function (event, ui) {
            $.application.ajax($(context).attr('data-signal-select') + ui.item.value + '&' + $(context).attr('data-componentprefix') + 'sid=' + ui.item.id + "&" +
                    $(context).attr('data-componentprefix') + 'column=' + ui.item.column, {}, false);
        }
    });
});

/**
 * Zajistí zvýraznění hledaného výrazu u autocompletů
 */
$.ui.autocomplete.prototype._renderItem = function (ul, item) {
    var term = this.term.split(' ').join('|');
    var re = new RegExp("(" + term + ")", "gi");
    var t = item.label.replace(re, "<b>$1</b>");
    return $("<li></li>")
            .data("item.autocomplete", item)
            .append("<a>" + t + "</a>")
            .appendTo(ul);
};

/**
 * Input navrhující možnosti pro fultextz ve filtru
 */
$.eachComponent('.fulltext', function (context) {
    $(context).not('input:disabled').autocomplete({
        source: function (request, response) {
            $.ajax({
                url: $(context).attr('data-signal-search') + request.term,
                success: function (data) {
                    response($.map(data, function (item) {
                        return {
                            id: item.id,
                            label: item.label,
                            value: item.value,
                            column: item.column
                        }
                    }));
                }
            });
        },
        select: function (event, ui) {
            $(context).val(ui.item.value);
        }
    });
});


/**
 * Formátování čísla
 */
$.eachComponent('.input_control_number', function (context) {

    var format = $.parseJSON($(context).attr('data-number'));
    if (format == null) {
        var parent = $(context).parent('div');
        format = $.parseJSON(parent.attr('data-number'));
    }

    var allow_empty = $.parseJSON($(context).attr('data-allow_empty'));

    $(context).bind('format', function () {
        var val = $(this).val();
        var num = val.toNumber();

        if (!allow_empty) {
            $(this).val(num.format(format.decimals, format.decimal_point, format.thousand_sep));
        }
    });

    $(context).change(function (e) {
        $(this).trigger('format');
    });

});



/**
 * Input pro rozsah hodnot
 */
$.eachComponent('.RangeInput', function (context) {

    var range = $.parseJSON($(context).attr('data-range'));
    var changeTimeout;
    var changeTimeoutDelay = 550;

    // Při kliknutí na ikonu se nastaví focus inputu a provede se příslušná akce
    $('.range', context).not('.disabled').click(function (e) {
        var up = (e.pageY - $(this).offset().top) <= ($(this).height() / 2);

        if (up) {
            $('input', context).focus().trigger('up');
        }
        else {
            $('input', context).focus().trigger('down');
        }
    });

    //Kliknutí vlevo při rozšířeném vzhledu
    $('.left', context).not('.disabled').click(function (e) {
        $('input', context).focus().trigger('down');
    });

    //Kliknutí vpravo při rozšířeném vzhledu
    $('.right', context).not('.disabled').click(function (e) {
        $('input', context).focus().trigger('up');
    });

    // Ovládání šipkami nahoru/dolu
    $('input', context).bind('keydown', function (e) {
        switch (e.keyCode) {
            case 38: // UP
                $(this).trigger('up');
                e.preventDefault();
                break;
            case 40: // DOWN
                $(this).trigger('down');
                e.preventDefault();
                break;
        }
    });

    // Ovládání kolečkem myši (jen nad tema, ktere maji tridu mousewheel)
    if ($(':input', context).is('.mousewheel')) {
        $(context).not('.disabled').mousewheel(function (e, velocity) {
            e.stopPropagation();
            e.preventDefault();
            if (velocity > 0) {
                $('input', context).focus().trigger('up');
            }
            else {
                $('input', context).focus().trigger('down');
            }
        });
    }

    // Akce nahoru a dolu
    $('input', context).not('input:disabled').bind('up down', function (e) {

        if (range.values) { // je zdaný pevný rozsah hodnot
            // hledame predchozi a nasledujici hodnotu k aktualni
            var index, prev, next, last;
            for (key in range.values) {
                if (Array.prototype[key] !== undefined) {
                    continue;
                }
                ;
                if (!last) {
                    last = key;
                }
                if (!prev) {
                    prev = key;
                }
                if (!next) {
                    next = key;
                }

                if (index) { // uz mame aktualni a hledame dalsi
                    next = key;
                    break;
                }

                if (range.values[key] == $(this).val()) { // hledame aktualni
                    index = key;
                    next = key;
                    prev = last;
                }

                last = key;
            }
            ;

            index = (e.type == 'up' ? next : prev);
            $(this).val(range.values[index]);
            $(this).data('index', index);

            clearTimeout(changeTimeout);
            changeTimeout = setTimeout(function () {
                $('input', context).change();
            }, changeTimeoutDelay);
        }
        else { // skace se po urcitych definovanych skocich
            var step = new Number(range.step) || 1;
            step *= (e.type == 'up') ? 1 : -1;
            var value = $(this).val().toNumber() + step;
            value = (range.min != null) ? Math.max(range.min, value) : value;
            value = (range.max != null) ? Math.min(range.max, value) : value;
            $(this).val(parseFloat(value.toFixed(10)));
            clearTimeout(changeTimeout);
            changeTimeout = setTimeout(function () {
                $('input', context).change();
            }, changeTimeoutDelay);
        }
    });

});

/**
 * Multiselect
 */
$.eachComponent('.multiselect', function () {
    $(".multiselect").multiselect({
        minWidth: 0,
        checkAllText: 'Vše',
        uncheckAllText: 'Zrušit',
        noneSelectedText: 'Nic není vybráno',
        selectedText: 'Zvolené',
        selectedAllText: 'Vše',
        selectedList: 1
    });
});

// ------------------------ State selection ------------------------------------

$().ready(function () {
    $('html').bind('click dblclick contextmenu', function () {
        $('.selection-container-picker').hide();
    });
});

$.eachComponent('.selection', function (context) {
    $(context).on('click', '.control', function (event) {
        event.stopPropagation();
        var height = $(this).height();
        var position = $(this).position();
        $('.selection-container-picker', context).css("top", height + 5).css("left", position.left + 5);
        $('.selection-container-picker', context).toggle();
    });

    $(context).on('click', '.selection-item', function (event) {
        var control = $(".control", context);
        var id = ($(this).attr('id'));
        var old_class = control.data("val");

        $('.selection-control', context).val(id);

        control.data('val', id);
        var cl = control.attr("class");
        control.attr("class", cl.replace(old_class, id));
    });
});

// ------------------------ WysiwygTextArea ------------------------------------

$.eachComponent('.ck_editor', function (context) {
    var config = $(context).data('config');
    var id = $(context).attr('id');
    config.width = $(context).width();
    config.height = $(context).height();
    config.enterMode = CKEDITOR.ENTER_BR;
    config.entities = false;

    // Obnovení po ajaxu
    $(context).ckeditor(config);
});

// ------------------------ DatetimeInput ------------------------------------

$.eachComponent('.datetimeinput', function (context) {
    // Při kliknutí na ikonu se nastaví focus inputu
    $('.calendar', context).not('.disabled').click(function () {
        $('input', context).focus();
    });

    if ($(context).hasClass('noseconds')) {
        $('input', context).datetimepicker({
            'timeFormat': 'HH:mm'
        });
    } else {
        $('input', context).datetimepicker();
    }
});

// ------------------------ TimeInput ------------------------------------

$.eachComponent('.timeinput', function (context) {
    // Při kliknutí na ikonu se nastaví focus inputu
    $('.clock', context).not('.disabled').click(function () {
        $('input', context).focus();
    });

    if ($(context).hasClass('noseconds')) {
        $('input', context).timepicker({
            'timeFormat': 'HH:mm'
        });
    } else {
        $('input', context).timepicker();
    }
});


$.eachComponent('.unit-wraper', function (context) {
    var unitWidth = $('.unit', context).outerWidth();
    var input = $('input', context);
    var inputWidth = input.outerWidth();
    input.css('padding-right', unitWidth + parseInt(input.css('padding-right')));
    input.outerWidth(inputWidth);
});

$.eachComponent('#content-footer', function (context) {
    $("#content-header-buttons").html('');
    $(".pair:not(.hidden) > input", context).each(function (i, e) {
        var input_clon = $(e).parent().clone(false);
        if ($('input', input_clon).attr('id'))
            $('input', input_clon).removeAttr('id');
        $('input', input_clon).bind("click", function () {
            $(e).click();
        });
        $(input_clon).appendTo("#content-header-buttons");
    });
});

//oznacovani podskupin
$.eachComponent('.ui-multiselect-checkboxes', function (context) {

    $('input[name^="multiselect_frmformFilter-groups"]', context).click(function () {
        var isChecked = $(this).is(':checked');

        var group = $(this).siblings().html();
        var sibl = $(this).closest('li').next().find('input').siblings("span");//.siblings().attr('checked', true);//.nextAll().children('label[]').attr('aria-selected', true);
        while (sibl.length > 0) {
            if (sibl.html().search('&nbsp;&nbsp;&nbsp;&nbsp;' + group + ',') == 0) {
                sibl.siblings().attr('checked', isChecked);
                if (isChecked)
                    $("option[value=" + sibl.siblings().attr('value') + "]", '#frmformFilter-groups').attr('selected', 'selected');
                else
                    $("option[value=" + sibl.siblings().attr('value') + "]", '#frmformFilter-groups').removeAttr('selected');
            }
            sibl = sibl.closest('li').next().find('input').siblings("span");//.siblings().attr('checked', true);//.nextAll().children('label[]').attr('aria-selected', true);
        }
    });

    $('input[name^="multiselect_frmform-groups"]', context).click(function () {
        var isChecked = $(this).is(':checked');

        var group = $(this).siblings().html();
        var sibl = $(this).closest('li').next().find('input').siblings("span");//.siblings().attr('checked', true);//.nextAll().children('label[]').attr('aria-selected', true);
        while (sibl.length > 0) {
            if (sibl.html().search('&nbsp;&nbsp;&nbsp;&nbsp;' + group + ',') == 0) {
                sibl.siblings().attr('checked', isChecked);
                if (isChecked)
                    $("option[value=" + sibl.siblings().attr('value') + "]", '#frmform-groups').attr('selected', 'selected');
                else
                    $("option[value=" + sibl.siblings().attr('value') + "]", '#frmform-groups').removeAttr('selected');
            }
            sibl = sibl.closest('li').next().find('input').siblings("span");//.siblings().attr('checked', true);//.nextAll().children('label[]').attr('aria-selected', true);
        }
    });
});

$.eachComponent('.general_states', function (context) {
    var disabled = $(context).data('disabled');
    var descriptions = $(context).data('descriptions');
    $('option', context).each(function () {
        if (disabled[$(this).val()]) {
            $(this).attr('disabled', 'disabled');
        }
        if (descriptions[$(this).val()]) {
            $(this).attr('title', descriptions[$(this).val()]);
        }
    });
});
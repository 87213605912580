$.eachComponent('.chooser', function(context) {
    var clicktimer;
    
    $('input[type="radio"]', context).click(function(event) {
        event.stopPropagation();
    });
    $('input[type="checkbox"]', context).click(function(event) {
        event.stopPropagation();
    });
    
    $('.dataGrid tr', context).click(function(event) {
        var input = $(this).find('input:checkbox, input:radio');
        if(clicktimer){
            clearTimeout(clicktimer);
            clicktimer= null;
            // double click event
            input.attr('checked', 'checked');
            $('input[type="submit"].save', context).click();
        }
        else clicktimer= setTimeout(function(e) {
            clicktimer= null;
            // single click event
            if(input.attr('checked')) {
                input.removeAttr('checked');
            }
            else {
                input.attr('checked', 'checked');
            }
        },300);
    });
    
    $('input[type="submit"]', context).click(function() {
        var choosed = {};
        
        $('.dataGrid tr', context).find('input:checked').each(function() {
            if($(this).is(':checkbox')) {
                var name = $(this).attr('name');
                choosed[name] = 1;
                $('.dataGrid tr', context).find('input[type="text"][name="'+$(this).attr('name')+'"]').each(function() {
                    if($(this).val() !== '') {
                        choosed[name] = $(this).val();
                    }
                });
            }
            else if($(this).is(':radio')) {
                choosed[$(this).val()] = 1;
            }
        })
        $('input[name="choosed"]', context).val(JSON.stringify(choosed));
    });
    
    $('input[name="check_all"]', context).click(function() {
        $('.dataGrid tr', context).find('input:checkbox').prop('checked', $(this).prop('checked'));
    });
    
    $('.icon-16.right', context).click(function(){
        var line=$(this).parents('tr');
        $('input[type=checkbox]',line).attr('checked', 'checked');
        $('input[type="submit"].save', context).click();
    });
});

// tlačítko pro otevření grafu
$.eachComponent('.graphButton', function(btn) {
    $(btn).click(function(e){
        e.preventDefault();
        var height = Math.floor(screen.height);
        var width = Math.floor(screen.width * 2 / 3);
        var left = screen.left + Math.floor((screen.width - width)/2);
        console.log(height, width, left);
        window.open($(btn).attr('href'), "graph", "width="+width+",height="+height+",left="+left+",top=0,menubar=no,resizable=yes,menubar=nolocation=no")
    });
});

// okno s grafem
$.eachComponent('#graph-container', function(graphContainer) {
    $(graphContainer).width($(window).width());

    $(graphContainer).height(window.innerHeight);
    console.log("canvasheight:" + $(graphContainer).height());
    console.log("windowheight:" + $(window).height());
    var coords = $(graphContainer).data('coords');
    var graph = new SynopsisGraph(graphContainer, coords);
    graph.fullscreenMode(true);
    graph.nodeEditClick = function(node) {
        window.opener.location.href = "/www/administration/configurator-wizards-cards/edit/" + node.attr.attributes.hidden.id + "?tile_id=4&menu_id=49&lang=cs";
    };
    graph.nodeDeleteClick = function(node) {
        if (confirm("Do you really want to delete card?")) {
            window.opener.$.application.ajax("/www/administration/configurator-wizards/edit/1?cardId=" + node.attr.attributes.hidden.id + "&tile_id=4&menu_id=49&lang=cs&do=graphDeleteCard");
            graph.deleteNode(node.id);
        }
    }
    graph.draw();
});
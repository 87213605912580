/**
 * Plugin pro navázání událostí na jednotlivé komponenty na stránce
 * V kódu stačí volat $.eachComponent('.selector', function(context) {...}); kde selector určuje komponentu (komponenty)
 * a function je callback funkce volaná nad každou takovou komponentou při document.ready s parametrem context udávájící
 * jednu konkrétní komponentu
 *
 * @author Jan Preuss
 */

(function($) {

    // seznam komponent (selektoru) a k nim prirazenych callbacků, které se snaží navazovat
    var components = {};

    //
    var ready = false;

    $.extend({
        eachComponent: function(param, callback) {
            if(callback === undefined) {
                switch(param) {
                    case 'reload':
                        if(ready) {
                            // napojení fcí na všechny prvky podle zadaných selektorů
                            $.each(components, function(selector, callbacks) {
                                $(selector).each(function() {
                                    var component = this;
                                    var componentLoaded = $(component).data('component-loaded') || new Array();
                                    $.each(callbacks, function(index, callback) {
                                        // fce se napojí jen na prvky, které jsou ve stránce nové
                                        if($.inArray(callback, componentLoaded) === -1) {
                                            callback(component);
                                            componentLoaded.push(callback);
                                        }
                                    });
                                    $(component).data('component-loaded', componentLoaded);
                                });
                            });
                        }
                        break;
                }
            }
            else {  // registrace nových callback funkcí při zavolání $.eachComponent('.selector', function() {...});
                if(components[param] === undefined) {
                    components[param] = new Array();
                }
                components[param].push(callback);
            }
        }
    });

    $(document).ready(function() {
        ready = true;
        $.eachComponent('reload');
    });
})(jQuery);